import React, {useEffect} from "react";
import Sidebar from "components/SideBar";
import {Outlet} from "react-router-dom";
import useMinHeight from "hooks/useMinHeight";
import {selectIsSidebarOpen} from "../features/sidebar/sidebarSlice";
import {useDispatch, useSelector} from "react-redux";

const MainLayout: React.FC = () => {
  const {minHeight, contentRef, minContentHeight, updateHeights} =
    useMinHeight();
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    updateHeights();
    window.addEventListener("resize", updateHeights);
    return () => {
      window.removeEventListener("resize", updateHeights);
    };
  });

  return (
    <div className="" ref={contentRef}>
      <div className="w-52">
        <Sidebar />
      </div>
      <div
        className={`transition-all duration-300 ${isSidebarOpen ? "ml-52" : "ml-[84px]"} pr-3`}
        style={{height: minContentHeight}}>
        <main
          id="content"
          style={{minHeight}}
          className="flex-grow pt-8 bg-gray-50 rounded-xl">
          <div className="pr-5 pl-5 pb-3">
            <Outlet />
          </div>
        </main>
        <div className="pb-3"></div>
      </div>
    </div>
  );
};

export default MainLayout;
