import React from 'react';

interface ArrowRightProps {
  size?: number;
  strokeWidth?: number;
}

export const ArrowRight: React.FC<ArrowRightProps> = ({ size = 24, strokeWidth = 2 }) => (
  <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width={size}
       height={size}
       viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={strokeWidth}
       strokeLinecap="round"
       strokeLinejoin="round">
    <path d="M5 12h14"/>
    <path d="m12 5 7 7-7 7"/>
  </svg>
);

export default ArrowRight;


