import {createSlice} from '@reduxjs/toolkit';


interface HrState {

    activeTab?: string;
}

const initialState: HrState = {

    activeTab: 'HR Documents'
};



const hrSlice = createSlice({
    name: 'hr',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },

    },

});

export const { setActiveTab} = hrSlice.actions;
export default hrSlice.reducer;
