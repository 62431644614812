import React, {useEffect, useRef, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Payer} from "types/payer";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {PayerThunks} from "./payerThunks";
import {setIsEditing, setPayer} from "features/payer/payerSlice";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";
import {setActiveTab} from "../location/locationSlice";
import CheckCircle from "assets/images/icons/CheckCircle";
import XCircle from "assets/images/icons/XCircle";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import Alert from "assets/images/icons/Alert";
import DotsMenu from "components/DotsMenu";

const PayerTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const payers = useSelector((state: RootState) => state.payer.payers);
    const refresh = useSelector((state: RootState) => state.payer.refresh);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.payer.pagy);
    const activeTab = useSelector((state: RootState) => state.payer.activeTab);
    const dotsMenuRef = useRef<HTMLDivElement>(null);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    const [visibleColumns, setVisibleColumns] = useState({
        id: true,
        name: true,
        address: true,
        payerType: true,
        phoneNumber: true,
        externalId: true,
        status: true
    });

    interface VisibleColumnsType {
        id: boolean,
        name: boolean,
        address: boolean,
        payerType: boolean,
        phoneNumber: boolean,
        externalId: boolean,
        status: boolean
    }
    const searchByOptions = [
        {label: 'ID', value: 'id', operator: '=='},
    ];

    const columnOptions = [
        { label: 'Id', value: 'id', isVisible: visibleColumns.id },
        { label: 'Name', value: 'name', isVisible: visibleColumns.name },
        { label: 'Address', value: 'address', isVisible: visibleColumns.address },
        { label: 'Payer type', value: 'payerType', isVisible: visibleColumns.payerType },
        { label: 'Phone number', value: 'phoneNumber', isVisible: visibleColumns.phoneNumber },
        { label: 'External id', value: 'externalId', isVisible: visibleColumns.externalId },
        { label: 'Status', value: 'status', isVisible: visibleColumns.status },
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab === 'Active' ? 'Active' : 'Disabled'
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }
        await dispatch(PayerThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };
    

    const handleDeactivate = (payerId: string) => {
        dispatch(PayerThunks.deactivate(payerId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (payerId: string) => {
        dispatch(PayerThunks.reactivate(payerId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(PayerThunks.show(id));
    };

    const handleOpenNewPayerDrawer = () => {
        dispatch(setPayer({
            id: '',
            name: '',
            address: '',
            payerType: '',
            phoneNumber: '',
            externalId: '',
            status: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    useEffect(() => {
        fetchData({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10});
    }, [refresh]);


    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            pageable={true}
            recordsName={'Payers'}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewPayerDrawer} label="" icon={<Plus/>}/>}
        >
            <thead>
            <tr>
                {visibleColumns.id && <TableHeader label="Id"/>}
                {visibleColumns.name && <TableHeader label="Name"/>}
                {visibleColumns.address && <TableHeader label="Address"/>}
                {visibleColumns.payerType && <TableHeader label="Payer type"/>}
                {visibleColumns.phoneNumber && <TableHeader label="Phone number"/>}
                {visibleColumns.externalId && <TableHeader label="External id"/>}
                {visibleColumns.status && <TableHeader label="Status"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {payers.map((payer: Payer) => {
                const userOptions = [];
                if (payer.status === "Active") {
                    userOptions.push({
                        label: 'Deactivate',
                        icon: <Alert />,
                        onClick: () => handleDeactivate(payer.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                if (payer.status === "Disabled") {
                    userOptions.push({
                        label: 'Reactivate',
                        onClick: () => handleReactivate(payer.id),
                        hoverClass: "hover:bg-cyan-100",
                    });
                }

                return (
                <TableRow
                    key={payer.id}
                    onClick={handleOpenDrawer(payer.id)}
                    className="cursor-pointer hover:bg-cyan-50 focus:bg-cyan-100 dark:hover:bg-neutral-700 dark:text-neutral-300"
                >
                    {visibleColumns.id && <TableCell>{payer.id}</TableCell>}
                    {visibleColumns.name && <TableCell>{payer.name}</TableCell>}
                    {visibleColumns.address && <TableCell>{payer.address}</TableCell>}
                    {visibleColumns.payerType && <TableCell>{payer.payerType}</TableCell>}
                    {visibleColumns.phoneNumber && <TableCell>{payer.phoneNumber}</TableCell>}
                    {visibleColumns.externalId && <TableCell>{payer.externalId}</TableCell>}
                    {visibleColumns.status && (
                        <TableCell>
                            <span
                                className={`flex items-center text-xs font-semibold px-2 py-0.5 rounded-full w-[112px]
                                    ${payer.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {payer.status === 'Active' ? <CheckCircle className="mr-2"/> : <XCircle className="mr-2"/>}

                                <div className="pl-1">
                                    {payer.status === 'Active' ? payer.status : 'Disabled'}
                                </div>
                            </span>
                        </TableCell>)}
                    <TableCell>
                        <DotsMenu options={userOptions} />
                    </TableCell>
                </TableRow>
            )}
            )}
            </tbody>
        </Table>
    );
};

export default PayerTable;
