import React from "react";

const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="w-8 h-8 border-2 border-cyan-400 border-dashed rounded-full animate-spin  "></div>
    </div>
  );
};

export default LoadingSpinner;
