
export const TimeZone = [
    'Eastern Time (US & Canada)',
    'Central Time (US & Canada)',
    'Mountain Time (US & Canada)',
    'Pacific Time (US & Canada)'
];

export interface LocationInterface {
    id: string;
    name: string;
    address: string;
    phoneNumber: string;
    timeZone: string;
    npi: string;
    taxId: string;
    taxonomyCode: string;
    description: string;
    usersCount?: any;
    status?: string;
}

export class Location implements LocationInterface {
    id: string;
    name: string;
    address: string;
    phoneNumber: string;
    timeZone: string;
    npi: string;
    taxId: string;
    taxonomyCode: string;
    description: string;
    usersCount?: any;
    status?: any;

    constructor(data: Partial<LocationInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.address = data.address!;
        this.phoneNumber = data.phoneNumber!;
        this.timeZone = data.timeZone!;
        this.npi = data.npi!;
        this.taxId = data.taxId!;
        this.taxonomyCode = data.taxonomyCode!;
        this.description = data.description!;
        this.usersCount = data.usersCount;
        this.status = data.status || 'active';
    }

    toJson(): LocationInterface {
        return {
            id: this.id,
            name: this.name,
            address: this.address,
            phoneNumber: this.phoneNumber,
            timeZone: this.timeZone,
            npi: this.npi,
            taxId: this.taxId,
            taxonomyCode: this.taxonomyCode,
            description: this.description,
            status: this.status,
        };
    }
}
