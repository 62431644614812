import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserInvitation} from 'types/userInvitation';
import {validateEmail} from "utils/validators";
import TextInput from "components/inputs/TextInput";
import {RoleThunks} from "../role/roleThunks";
import {Role} from 'types/role';
import {User} from "types/user";
import {UserInvitationThunks} from "./userInvitationThunk";
import SSNInput from "components/inputs/SSNInput";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";
import {CredentialThunks} from "../credential/credentialThunks";
import {Credential} from "types/credential";
import CustomSelect from "components/inputs/CustomSelect";
import SubmitButton from "../../components/SubmitButton";


interface InvitationsFormProps {
    closeDrawer: () => void;
    isDrawerOpen: boolean;
}

interface SupervisorUserIds {
    [key: string]: string;
}

interface FormData {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    personalEmail: string;
    ssn: string;
    roleId: string | undefined;
    supervisorUserIds: SupervisorUserIds;
    credentialIds: string[];
}


const InvitationsForm: React.FC<InvitationsFormProps> = ({closeDrawer, isDrawerOpen}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [emailError, setEmailError] = useState('');
    const [ssn, setSSN] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [formData, setFormData] = useState<FormData>({
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        personalEmail: '',
        ssn: '',
        roleId: '',
        supervisorUserIds: {},
        credentialIds: []
    });
    const status = useSelector((state: RootState) => state.userInvitation.status);
    const roles = useSelector((state: RootState) => state.role.roles);
    const credentials = useSelector((state: RootState) => state.credential.credentials);
    const selectableRoles = useSelector((state: RootState) => state.role.selectableRoles);
    const [isFormValid, setIsFormValid] = useState(false);
    const credentialOptions = credentials.map((credential: Credential) => ({
        value: credential.id,
        label: credential.name
    }));


    useEffect(() => {
        const supervisorUserIds = Object.values(formData.supervisorUserIds);
        setIsFormValid(
            formData.email !== '' &&
            formData.firstName !== '' &&
            formData.lastName !== '' &&
            formData.phoneNumber !== '' &&
            formData.personalEmail !== '' &&
            formData.ssn !== '' &&
            formData.roleId !== '' &&
            supervisorUserIds.length === selectableRoles.length
        );
    }, [formData]);

    useEffect(() => {
        setFormData({
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            personalEmail: '',
            ssn: '',
            roleId: '',
            supervisorUserIds: {},
            credentialIds: []
        });
        setSSN('')
        setPhoneNumber('')
    }, [isDrawerOpen]);

    useEffect(() => {
        const loadRoles = async () => {
            try {
                await dispatch(RoleThunks.index());
                await dispatch(RoleThunks.selectable());
                await dispatch(CredentialThunks.index());
            } catch (err) {
            }
        };
        loadRoles();
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {id, value} = e.target;
        if (id === 'email') {
            if (validateEmail(value)) {
                setEmailError('');
            } else {
                setEmailError('Please enter a valid email address.');
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSupervisorChange = (role: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            supervisorUserIds: {
                ...prevData.supervisorUserIds,
                [role]: value
            }
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (emailError) return;
        const parsedData = {
            ...formData,
            supervisorUserIds: Object.values(formData.supervisorUserIds)
        }
        const invitation = new UserInvitation(parsedData);
        dispatch(UserInvitationThunks.create(invitation)).then(
            () => {
                setFormData({
                    email: '',
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    personalEmail: '',
                    ssn: '',
                    roleId: '',
                    supervisorUserIds: {},
                    credentialIds: []
                });
                closeDrawer();
            }
        );
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-[158px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                    <div className=" mx-auto self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                            Invite a new Staff member
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            The user will receive a link to setup their account and create a password.
                        </div>

                    </div>
                </div>
                <div className="p-4 sm:p-7 px-4 sm:px-6 w-full">
                    <form>
                        <div className="flex w-full flex-col space-y-6 items-center">
                            <div className="grid grid-cols-2 gap-4 w-full ">
                                <TextInput
                                    id="firstName"
                                    type="text"
                                    label="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    id="lastName"
                                    type="text"
                                    label="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex flex-col gap-[0.625rem] w-full ">
                                    <label htmlFor="roleId"
                                           className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                        Role
                                    </label>
                                    <CustomSelect
                                        options={roles.map((role: Role) => ({
                                            value: role.id,
                                            label: role.nameAlias
                                        }))}
                                        onChange={(selectedOption) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                ['roleId']: selectedOption.value
                                            }));
                                        }}
                                        value={formData.roleId}
                                    />
                                </div>
                            </div>
                            {selectableRoles.map((role: Role) => (
                                <div key={role.id} className="w-full">
                                    <div className="flex flex-col w-full gap-[0.625rem]">
                                        <label htmlFor="roleId"
                                               className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            {role.nameAlias}
                                        </label>
                                        <CustomSelect
                                            options={role.users?.map((user: User) => ({
                                                value: user.id,
                                                label: user.name
                                            }))}
                                            onChange={(selectedOption) => {
                                                handleSupervisorChange(role.name, selectedOption.value);
                                            }}
                                            value={formData.supervisorUserIds[role.name] || ''}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="w-full">
                                <div className="flex flex-col gap-[0.625rem] w-full">
                                    <label htmlFor="roleId"
                                           className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                        Credentials
                                    </label>
                                    <CustomSelect
                                        options={credentialOptions}
                                        isMulti={true}
                                        onChange={(selectedOptions) => {
                                            const selectedValues = selectedOptions.map((option: any) => option.value);
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                credentialIds: selectedValues
                                            }));
                                        }}
                                        value={formData.credentialIds}
                                    />
                                </div>
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="email"
                                    error={emailError}
                                    type="text"
                                    label=" Company email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="personalEmail"
                                    type="text"
                                    label="Personal Email"
                                    value={formData.personalEmail}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <PhoneNumberInput
                                    id="phoneNumber"
                                    label="Phone Number"
                                    value={formData.phoneNumber || ""}
                                    onChange={handleChange}
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}
                                />
                            </div>
                            <div className="w-full pb-4">
                                <SSNInput
                                    id="ssn"
                                    setSSN={setSSN}
                                    ssn={ssn}
                                    label="SSN"
                                    value={formData.ssn || ""}
                                    onChange={(e) => handleChange(e)}
                                />

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      disabled={status === 'loading' || !isFormValid}
                                      data-hs-overlay="#hs-basic-modal"
                                      label={'Invite'}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={closeDrawer}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default InvitationsForm;
