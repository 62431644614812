    import React, {useEffect, useState} from "react";
    import { CredentialThunks } from "features/credential/credentialThunks";
    import {useDispatch, useSelector} from "react-redux";
    import {AppDispatch, RootState} from "store/store";
    import Drawer from "components/Drawer";
    import CredentialTable from "features/credential/CredentialTable";
    import CredentialForm from "features/credential/CredentialForm";
    import CredentialDetails from "features/credential/CredentialDetails";

    const CredentialsPage: React.FC = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);
        const isEditing = useSelector((state: RootState) => state.credential.isEditing);

        return (
            <div>
                <div >
                    <div className="relative">
                        <CredentialTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                            {isEditing ? <CredentialForm closeDrawer={closeDrawer} /> : <CredentialDetails onClose={closeDrawer} />}
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default CredentialsPage;
