import {createSlice} from '@reduxjs/toolkit';
import {PermissionSchemeGroupThunks} from "./permissionSchemeGroupThunks";
import {showToast} from "components/ToastContainer";
import {RoleThunks} from "./roleThunks";

export interface RoleState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    roles?: any;
    selectableRoles?: any;
    refresh: number;
    permissionSchemeGroups: any;
    pagy?: any;
    role?: any;
}

const initialState: RoleState = {
    status: 'idle',
    error: null,
    roles: [],
    refresh: 0,
    role: {name: '', nameAlias: ''},
    selectableRoles: [],
    permissionSchemeGroups: [],
    pagy: {}
};

const RoleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRole: (state, action) => {
          state.role = action.payload;
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(RoleThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.role = action.payload;
                state.refresh += 1;
                state.roles = [...state.roles, action.payload];
                showToast('Role created successfully', "success");
            })
            .addCase(RoleThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.role = action.payload;
                showToast('Role updated successfully', "success");
            })
            .addCase(RoleThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.role = action.payload;
            })
            .addCase(PermissionSchemeGroupThunks.index.fulfilled, (state, action) => {
                state.permissionSchemeGroups = action.payload;

            })
            .addCase(RoleThunks.selectable.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.selectableRoles = action.payload.data;
            })
            .addCase(RoleThunks.selectable.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.selectable.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(RoleThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(RoleThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(RoleThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setRole} = RoleSlice.actions;
export {initialState as roleInitialState};
export default RoleSlice.reducer;
