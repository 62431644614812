import React from "react";
import {formatPhoneNumber} from "utils/caseConverter";

interface PhoneNumberDisplayProps {
  value: string;
}

const PhoneNumberDisplay: React.FC<PhoneNumberDisplayProps> = ({value}) => {
  return <span>{formatPhoneNumber(value)}</span>;
};

export default PhoneNumberDisplay;
