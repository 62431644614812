import React from "react";

const Activity = () => (

<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="activity">
        <path id="Vector" d="M15.3333 8H12.6667L10.6667 14L6.66667 2L4.66667 8H2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
</svg>

    );
export default Activity;
