import React from 'react';

interface BlockedProps {
    className?: string
}

const Blocked = ({className}: BlockedProps) => (

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         className="size-3.5">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"/>
    </svg>
);
export default Blocked;
