import React, { } from 'react';
import LoginForm from 'features/auth/components/Login';
import backgroundLogIn from "assets/images/backgroundLogIn.png";
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from "layouts/AuthLayout";

const LoginPage: React.FC = () => {
    return (
        <div>
            <div className="flex flex-col pr-20 pl-20 pt-20 max-w-120 md:min-w-96 sm:min-w-64">

                <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                    Sign in to your account
                </div>
                <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                    Welcome to New Hope
                </div>
                <LoginForm/>
                <Link
                    to={'/forgot-password'}
                    data-testid={"forgot-password"}
                    className="self-center mt-6 mb-6 text-sm font-light tracking-normal leading-5 text-center underline text-zinc-400 hover:text-cyan-400"
                    style={{cursor: 'pointer'}}>
                    Forgot password?
                </Link>
            </div>
        </div>
    );

};

export default LoginPage;
