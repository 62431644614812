import React, {useState} from "react";
import SidebarContent from "features/sidebar/SidebarContent";
import CompressedSidebarContent from "features/sidebar/CompressedSidebarContent";
import Drawer from "components/Drawer";
import ProfileDetails from "features/user/ProfileDetails";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import ProfileEdit from "../features/user/ProfileEdit";
import {setIsEditing} from "../features/user/userSlice";

const Sidebar: React.FC = () => {
  const [isNewDrawerOpen, setIsNewDrawerOpen] = useState(false);
  const closeNewDrawer = () => setIsNewDrawerOpen(false);
  const isEditing = useSelector((state: RootState) => state.user.isEditing);
  const dispatch = useDispatch<AppDispatch>();

  const handleCloseDrawer = () => {
    setIsNewDrawerOpen(false);
    dispatch(setIsEditing(false));
  };
  return (
    <div className="z-10 flex-col justify-center items-start gap-[148px] inline-flex flex-grow">
      <div className="top-3 z-10"></div>
      <SidebarContent setIsNewDrawerOpen={setIsNewDrawerOpen} />
      <CompressedSidebarContent setIsNewDrawerOpen={setIsNewDrawerOpen} />
      <Drawer isOpen={isNewDrawerOpen} onClose={closeNewDrawer}>
        {isEditing ? (
          <ProfileEdit onClose={handleCloseDrawer} />
        ) : (
          <ProfileDetails
            closeDrawer={closeNewDrawer}
            onClose={closeNewDrawer}
          />
        )}
      </Drawer>
    </div>
  );
};

export default Sidebar;
