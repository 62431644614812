import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {useNavigate} from "react-router-dom";
import {selectUserCredentials} from "features/auth/authSlice";
import {AuthThunks} from "features/auth/authThunks";
import {showToast} from "components/ToastContainer";
import usePinInput from "hooks/usePinInput";

const useSecondStepVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userCredentials = useSelector(selectUserCredentials);
  const status = useSelector((state: RootState) => state.auth.status);
  const {
    pin,
    firstInputRef,
    error,
    isFormValid,
    handlePinChange,
    handleKeyDown,
    handlePaste,
    setError,
  } = usePinInput({initialPinLength: 6});

  useEffect(() => {
    if (!userCredentials) {
      navigate("/login");
    } else {
      firstInputRef.current?.focus();
    }
  }, [userCredentials, navigate]);

  const handleResend = async () => {
    if (userCredentials) {
      try {
        await dispatch(AuthThunks.login(userCredentials)).then(resultAction => {
          if (AuthThunks.login.fulfilled.match(resultAction)) {
            showToast("Verification code resent to your email", "success");
          }
        });
      } catch (err) {
        showToast("Error resending the code", "error");
      }
    } else {
      showToast("User credentials are missing", "error");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    const fullPin = pin.join("");

    try {
      const resultAction = await dispatch(AuthThunks.verifyCode(fullPin));
      if (AuthThunks.verifyCode.fulfilled.match(resultAction)) {
        navigate("/home");
      }
    } catch (err) {
      showToast("Invalid verification code", "error");
    }
  };

  return {
    pin,
    firstInputRef,
    error,
    isFormValid,
    handlePinChange,
    handleKeyDown,
    handlePaste,
    handleSubmit,
    handleResend,
    status,
  };
};

export default useSecondStepVerification;
