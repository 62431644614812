import React from 'react';
import {FaUserCircle} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {UserInvitation} from "types/userInvitation";
import LoadingSpinner from "components/LoadingSpinner";
import {UserInvitationThunks} from "features/userInvitation/userInvitationThunk";
import ChevronRight from "assets/images/icons/ChevronRight";
import SSNDisplay from "components/SSNDisplay";
import PhoneNumberDisplay from "components/PhoneNumberDisplay";


interface UserInvitationDetailsProps {
    onClose: () => void;
}

const UserInvitationDetails: React.FC<UserInvitationDetailsProps> = ({onClose}) => {
    const dispatch = useDispatch<AppDispatch>();
    const userInvitation = useSelector((state: RootState) => state.userInvitation.userInvitation as UserInvitation);

    const handleResend = () => {
        dispatch(UserInvitationThunks.create(userInvitation));
    }

    const handleCancelInvitation = () => {
        dispatch(UserInvitationThunks.cancel(userInvitation.id));
    }

    return (
        <div className="flex flex-col h-screen">
            {userInvitation !== null &&
                <div className="flex-1 flex flex-col">
                    <div className="flex-1 p-3 overflow-y-auto">
                        <div className="h-full flex flex-col overflow-y-auto overflow-hidden [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                            <div className="mt-5 p-5 flex flex-col justify-center items-center text-center">
                                <FaUserCircle className="flex-shrink-0 size-16 rounded-full text-cyan-400"/>
                                <div className="mt-2">
                                    <h3 className="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                                        {userInvitation?.firstName} {userInvitation?.lastName}
                                    </h3>
                                </div>
                            </div>
                            <div className="h-full px-5">
                                <div className="mt-5">
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Employee ID:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {userInvitation?.id}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Email:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {userInvitation?.email}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Personal Email:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center ">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {userInvitation?.personalEmail}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                SSN:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2">
                                            <div className="flex items-center gap-x-2">
                                                <span className="text-sm text-gray-800 dark:text-neutral-200">
                                                    <SSNDisplay value={userInvitation?.ssn}/>
                                                </span>
                                            </div>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Phone number:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                <PhoneNumberDisplay value={userInvitation?.phoneNumber}/>
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Role:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {userInvitation?.role?.name}
                                            </p>
                                        </dd>
                                    </dl>
                                    <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1 flex items-center">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Status:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2 flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {userInvitation?.status}
                                            </p>
                                        </dd>
                                    </dl>
                                    {userInvitation?.supervisors?.map(supervisor => (
                                        <dl key={supervisor.id} className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                            <dt className="col-span-1 flex items-center">
                                                <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                    {supervisor.role.nameAlias}:
                                                </p>
                                            </dt>
                                            <dd className="col-span-2 flex items-center">
                                                <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                    {supervisor.user.name}
                                                </p>
                                            </dd>
                                        </dl>
                                    ))}
                                    <dl className="py-2 justify-start gap-2 border-t border-gray-200 dark:border-neutral-700">
                                        <div className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400" style={{ cursor: 'pointer' }} onClick={handleResend}>
                                            Resend invitation <ChevronRight />
                                        </div>
                                        <div className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400" style={{ cursor: 'pointer' }} onClick={handleCancelInvitation}>
                                            Cancel invitation <ChevronRight />
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="flex-shrink-0 px-4 pb-4 flex justify-end gap-5 border-t border-slate-200">
                        <button type="submit" onClick={onClose} className="mt-6 py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 bg-white text-gray-500 disabled:pointer-events-none disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                            Close
                        </button>
                    </footer>
                </div>
            }
            {userInvitation === null &&
                <div className="h-full"><LoadingSpinner/></div>
            }
        </div>
    );
};

export default UserInvitationDetails;
