import React, { useEffect, useState } from 'react';
import {FaUser, FaUserCircle} from "react-icons/fa";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/SubmitButton";
import { User } from "types/user";
import { RoleThunks} from "features/role/roleThunks";
import { validateEmail } from "utils/validators";
import {UserThunks} from "features/user/userThunk";
import SSNInput from "components/inputs/SSNInput";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";
import {showToast} from "components/ToastContainer";
import {setAuthUser} from "../auth/authSlice";
import {setIsEditing} from "./userSlice";

interface ProfileEditProps {
    onClose: () => void;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({ onClose }) => {
    const user = useSelector((state: RootState) => state.user.user);
    const [ssn, setSSN] = useState(user?.ssn);
    const dispatch = useDispatch<AppDispatch>();
    const [emailError, setEmailError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);

    const [formData, setFormData] = useState({
        ...user,
        roleId: user?.role?.id,
        supervisorUserIds: user?.supervisors?.map((s: User) => s.id) || []
    });

    useEffect(() => {
        setSSN(user?.ssn);
        setPhoneNumber(user?.phoneNumber);

    }, []);

    useEffect(() => {
        const loadRoles = async () => {
            try {
                await dispatch(RoleThunks.index());
                await dispatch(RoleThunks.selectable());
            } catch (err) {
                console.error(err);
            }
        };
        loadRoles();
    }, [dispatch]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { id, value } = e.target;
        if (id === 'email') {
            if (validateEmail(value)) {
                setEmailError('');
            } else {
                setEmailError('Please enter a valid email address.');
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (emailError) {
            showToast("Please enter a valid email address.", "error");
            return;
        }

        try {
            await dispatch(UserThunks.update(new User(formData))).then((resultAction) => {
                if(resultAction) {
                    dispatch(setAuthUser(resultAction.payload));
                    dispatch(setIsEditing(false));
                }
            });
        } catch (error) {
        }
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div className="h-[158px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                    <div className=" pl-2 self-stretch flex flex-row gap-3">
                        <div
                            className="flex-shrink-0 w-14 h-14 mt-1.5 bg-cyan-400 rounded-lg flex justify-center items-center">
                            <FaUser className="text-white size-8"/>
                        </div>

                    </div>
                </div>
                <div className="p-4 sm:p-7 px-4 sm:px-6 w-full">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col space-y-6 items-center">
                        <div className="w-full">
                                <TextInput
                                    id="firstName"
                                    type="text"
                                    label="First Name"
                                    value={formData.firstName || ""}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="lastName"
                                    type="text"
                                    label="Last Name"
                                    value={formData.lastName || ""}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="email"
                                    type="text"
                                    label="Email"
                                    value={formData.email || ""}
                                    onChange={handleChange}
                                    error={emailError}
                                />
                            </div>
                            <div className="w-full">
                                <TextInput
                                    id="personalEmail"
                                    type="text"
                                    label="Personal Email"
                                    value={formData.personalEmail || ""}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <PhoneNumberInput
                                    id="phoneNumber"
                                    label="Phone Number"
                                    value={formData.phoneNumber || ""}
                                    onChange={handleChange}
                                    phoneNumber={phoneNumber}
                                    setPhoneNumber={setPhoneNumber}

                                />
                            </div>
                            <div className="w-full">
                                <SSNInput
                                    id="ssn"
                                    ssn={ssn}
                                    setSSN={setSSN}
                                    label="SSN"
                                    value={formData.ssn || ""}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>
                    </form>
                    </div>
                </div>
            <footer className="flex-shrink-0 px-7 pb-4 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-[487px] border-t justify-center items-center gap-x-2 pt-8">
                        <button type="submit"
                                onClick={handleSubmit}
                                disabled={false}
                                data-hs-overlay="#hs-basic-modal"
                                className="py-2.5 px-3 w-full inline-flex justify-center items-center gap-x-2 text-sm font-semibold
                                                             rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500
                                                             disabled:pointer-events-none disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                            Update my profile
                        </button>
                    </div>
                    <div
                        className="flex w-[487px] justify-center items-center gap-x-2 pb-4 pt-3">
                        <button type="submit" onClick={onClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ProfileEdit;
