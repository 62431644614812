import React, {useEffect, useState} from 'react';
import SetupPassword from "features/auth/components/SetupPassword";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {useNavigate, useParams} from "react-router-dom";
import {showToast} from "components/ToastContainer";
import {AuthThunks} from "features/auth/authThunks";
import SubmitButton from "../../components/SubmitButton";


const ResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const status = useSelector((state: RootState) => state.auth.status);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        setIsFormValid(!!(formData.password && formData.confirmPassword));
    }, [formData]);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const {password, confirmPassword} = formData;
        if (params.token === undefined) {
            showToast('Invalid reset password link', 'error');
            navigate('/login');
            return;
        }

        await dispatch(AuthThunks.resetPassword({password, token: params.token})).then((resultAction) => {
            if (AuthThunks.resetPassword.fulfilled.match(resultAction)) {
                showToast('Password set successfully', 'success');
                navigate('/login');
            }
        });

    };

    return (

        <div>
            <div className="flex flex-col pr-20 pl-20 pt-20  max-w-120 md:min-w-96 sm:min-w-64">
                <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                    <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                            <span className="leading-tight">
                                Create new password
                            </span>
                    </div>

                </div>
                <div
                    className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                    We recommend you use a strong password to keep your account safe.
                </div>
                <div className="mt-[7px]">
                    <SetupPassword
                        formData={formData}
                        setFormData={setFormData}
                    />
                </div>
                <div className="pt-10">
                    <SubmitButton
                        className={"w-80 py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"}
                        onClick={handleSubmit}
                        label="Done"
                        disabled={status === 'loading' || !isFormValid}
                    />
                </div>
            </div>
        </div>

    )
};

export default ResetPasswordPage
