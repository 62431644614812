import React, {useState} from "react";
import Toast from "components/Toast";

interface ToastMessage {
  id: number;
  message: string;
  type: "normal" | "success" | "error" | "warning";
}

let toastId = 0;
let toastQueue: ToastMessage[] = [];
let setToastQueue: React.Dispatch<React.SetStateAction<ToastMessage[]>>;

export const showToast = (
  message: string,
  type: "normal" | "success" | "error" | "warning",
) => {
  const newToast: ToastMessage = {id: toastId++, message, type};
  toastQueue.push(newToast);
  setToastQueue([...toastQueue]);
  setTimeout(() => {
    toastQueue = toastQueue.filter(toast => toast.id !== newToast.id);
    setToastQueue([...toastQueue]);
  }, 5000);
};

const ToastContainer: React.FC = () => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);
  setToastQueue = setToasts;

  return (
    <div className="fixed top-14 right-8 z-1000 space-y-3 ">
      {toasts.map(toast => (
        <Toast key={toast.id} message={toast.message} type={toast.type} />
      ))}
    </div>
  );
};

export default ToastContainer;
