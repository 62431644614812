import React from "react";

const TableRow: React.FC<{ children: React.ReactNode, className?: string, onClick?: () => void }> = ({
                                                                                                         children,
                                                                                                         className = "",
                                                                                                         onClick
                                                                                                     }) => (
    <tr
        className={`cursor-pointer hover:bg-cyan-50 dark:hover:bg-neutral-700 dark:text-neutral-300 ${className}`}
        onClick={onClick}
    >
        {children}
    </tr>
);

export default TableRow;
