import React, {useEffect, useState} from "react";
import Table from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {UserCredential} from "types/userCredential";
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";
import {SearchType} from "types/search";
import {useParams} from "react-router-dom";
import {setActiveTab} from "features/hr/hrSlice"
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import DotsMenu from "components/DotsMenu";
import {setUserCredential,setIsEditing} from "../userCredential/userCredentialSlice";
import CheckCircle from "../../assets/images/icons/CheckCircle";
import XCircle from "../../assets/images/icons/XCircle";

import CircleAlertIcon from "../../assets/images/icons/CircleAlertIcon";
import TrashIcon from "../../assets/images/icons/TrashIcon";


const UserCredentialTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const credentials = useSelector((state: RootState) => state.userCredential.userCredentials);
    const refresh = useSelector((state: RootState) => state.userCredential.refresh);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userCredential.pagy);
    const params = useParams<{userId: string}>();
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    interface VisibleColumnsType {
        credential: boolean;
        licensingAuthority: boolean;
        status: boolean;
        expDate: boolean;
        reminder: boolean;
        notes: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState({
        credential: true,
        licensingAuthority: true,
        status: true,
        expDate: true,
        reminder: true,
        notes: true
    });

    const searchByOptions = [
        {label: 'Credential', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Credential', value: 'credential', isVisible: visibleColumns.credential},
        {label: 'Licensing Authority', value: 'licensingAuthority', isVisible: visibleColumns.licensingAuthority},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Exp Date', value: 'expirationDate', isVisible: visibleColumns.expDate},
        {label: 'Reminder', value: 'remindUserAt', isVisible: visibleColumns.reminder},
        {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes}
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        const { userId } = params;

        search.where.user_id = {
            operator: '==',
            value: userId as string
        };

        await dispatch(UserCredentialThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };
    const handleOpenDrawer = async (id: string) => {
        await dispatch(UserCredentialThunks.show(id));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    const handleOpenNewUserCredentialDrawer = () => {
        dispatch(setUserCredential({
            id: '',
            name: '',
            licensingAuthority: '',
            expirationDate: '',
            reminderDate: '',
            notes: '',
            userId: params.userId
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };


    useEffect(() => {
        fetchData({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10});
    }, [refresh]);

    const handleDelete = async (id: string) => {
        await dispatch(UserCredentialThunks.delete(id));
        setDotsMenuIsOpen(null);
    };


    return (
        <Table
            tabs={['HR Documents', 'Credentials', 'Trainings', 'Evaluations']}
            activeTab={'Credentials'}
            fetchData={fetchData}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            pagy={pagy}
            recordsName={'Credentials'}
            columns={true}
            blueButton={<BlueButton onClick={handleOpenNewUserCredentialDrawer} label={''} icon={<Plus/>}/>}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.credential && <TableHeader label="Credential" sortBy="name"/>}
                {visibleColumns.licensingAuthority &&
                    <TableHeader label="Licensing Authority" sortBy="licensingAuthority"/>}
                {visibleColumns.status && <TableHeader label="Status"/>}
                {visibleColumns.expDate && <TableHeader label="Exp Date" sortBy="expDate"/>}
                {visibleColumns.reminder && <TableHeader label="Reminder" sortBy="reminder"/>}
                {visibleColumns.notes && <TableHeader label="Notes"/>}
                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {credentials.map((cred: UserCredential) => {
                    const userOptions = [
                        {label: 'Edit', onClick: () => handleOpenDrawer(cred.id.toString())},
                        {label: 'Delete', onClick: () => handleDelete(cred.id), icon: <TrashIcon/>, hoverClass: "hover:bg-red-100 hover:text-red-800"}
                    ];
                    return (

                    <TableRow key={cred.id}>
                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>
                        {visibleColumns.credential &&
                            <TableCell
                                className="text-sm font-medium dark:text-neutral-200 underline text-cyan-600"
                                onClick={()=>handleOpenDrawer(cred.id)}>
                                {cred.name}
                            </TableCell>}
                        {visibleColumns.licensingAuthority && <TableCell>{cred.licensingAuthority}</TableCell>}
                        {visibleColumns.status &&
                            <TableCell>
                                <span
                                    className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-[123px]
                                    ${cred.status === 'Active' ? 'bg-green-100 text-green-800' : ''}
                                    ${cred.status === 'Inactive' ? 'bg-red-100 text-red-800' : ''}
                                    ${cred.status === 'Expiring soon' ? 'bg-yellow-100 text-yellow-800' : ''}
                                    `}
                                >
                                    {cred.status === 'Active' && <CheckCircle className="mr-2"/>}
                                    {cred.status === 'Inactive' && <XCircle className="mr-2"/>}
                                    {cred.status === 'Expiring soon' && <CircleAlertIcon className="mr-2"/>}

                                    <div className="pl-1">
                                        {cred.status}
                                    </div>
                                </span>
                            </TableCell>
                        }
                        {visibleColumns.expDate && <TableCell>{cred.expirationDate}</TableCell>}
                        {visibleColumns.reminder && <TableCell>{cred.remindUserAt || 'None'}</TableCell>}
                        {visibleColumns.notes && <TableCell>{cred.notes}</TableCell>}
                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                    )}
            )}
            </tbody>
        </Table>
    );
};

export default UserCredentialTable;
