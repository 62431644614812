import React from 'react';
import Select, {components, DropdownIndicatorProps} from 'react-select';
import ChevronDown from "assets/images/icons/ChevronDown";



const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? '#22d3ee' : '#e2e8f0',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)' ,
        borderRadius: '0.5rem',
        backgroundColor: 'white',
        minHeight: '2.5rem',
        fontSize: '0.875rem',
        '&:hover': {
            borderColor: '#22d3ee',
        },
        display: 'flex',
        alignItems: 'center',
        svg: {
            fontSize: '1rem',
            marginRight: '8px',
            strokeWidth: '0.05px',

        },
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: '#FFFFFF',
        borderRadius: '0.75rem',
        border: '1px solid #E2E8F0',
        color: '#E2E8F0',
        fontSize: '0.75rem',
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: '#6B7280',
        paddingLeft: '10px',
        fontSize: '0.75rem',
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        backgroundColor: '#e5e7eb',
        borderRadius: '100%',
        border: '1px solid #E2E8F0',
        width: '20px',
        height: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#1f2937;',
        cursor: 'pointer',
        padding: '',
        marginLeft: '4px',
        marginRight: '4px',
        marginTop: '4px',
        marginBottom: '2px',
        boxSizing: 'border-box',
        svg: {
            width: '12px',
            height: '12px',
            display: 'block',
            margin: 'auto',

        },
        ':hover': {
            backgroundColor: '#4b5563',
            color: '#f9fafb',
        },
    }),

    option: (provided: any, state: any) => ({
        ...provided,
        fontSize: '0.75rem',
        backgroundColor: state.isSelected ? '#ecfeff' : state.isFocused ? '#ecfeff' : 'white',
        color: state.isSelected ? '#1f2937' : '#000',
        ':hover': {
            backgroundColor: '#ecfeff',
        },
    }),
    menu: (provided: any) => ({
        ...provided,
        borderRadius: '0.75rem',
        border: '1px solid #e2e8f0',
        borderColor: '#e2e8f0',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
    }),
    dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: '5px',
        color: '#6b7280',
        svg: {
            strokeWidth: '1px',
        },
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
};

const DropdownIndicator = (props: DropdownIndicatorProps<{}, false>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDown/>
        </components.DropdownIndicator>
    );
};


interface CustomSelectProps {
    options?: any,
    onChange?: (selectedOptions: any) => void,
    isMulti?: boolean,
    value?: any,
}

const CustomSelect = ({options, isMulti, onChange, value}: CustomSelectProps) => (
    <Select
        components={{DropdownIndicator}}
        styles={customStyles}
        options={options}
        isMulti={isMulti as any}
        onChange={onChange}
        placeholder=""
        value={
            isMulti
                ? options.filter((option: any) => value?.includes(option.value))
                : options.find((option: any) => option.value === value) || null
        }
    />
);

export default CustomSelect;
