import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import SubmitButton from "components/SubmitButton";
import {setIsEditing} from "features/credential/credentialSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";
import {UserCredential} from "types/userCredential";
import DateInput from "components/inputs/DateInput";
import FileInput from "components/inputs/FileInput";
import TrashIcon from "assets/images/icons/TrashIcon";
import FileTextIcon from "assets/images/icons/FileTextIcon";
import CustomSelect from "components/inputs/CustomSelect";
import {Credential} from "types/credential";
import {CredentialThunks} from "../credential/credentialThunks";

interface UserCredentialFormProps {
    id?: string;
    closeDrawer: () => void;
    isDrawerOpen: boolean;
}

const UserCredentialForm: React.FC<UserCredentialFormProps> = ({id, closeDrawer, isDrawerOpen}) => {
    const userCredential = useSelector((state: RootState) => state.userCredential.userCredential);
    const credentials = useSelector((state: RootState) => state.credential.credentials);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedUserCredential, setUpdatedUserCredential] = useState(userCredential);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null); // New state for file
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid =
            updatedUserCredential?.licensingAuthority?.trim() &&
            updatedUserCredential?.expirationDate?.trim() &&
            updatedUserCredential?.remindUserAt?.trim() &&
            updatedUserCredential?.credentialId;

        setIsSaveDisabled(!isFormValid);
    }, [updatedUserCredential]);

    useEffect(() => {
        setUpdatedUserCredential(userCredential);
        setIsSaveDisabled(!userCredential?.name);
    }, [userCredential]);

    useEffect(() => {
        dispatch(CredentialThunks.index());
    }, [dispatch]);

    useEffect(() => {
        if(!isDrawerOpen)
            handleClose()
    }, [isDrawerOpen]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedUserCredential({...updatedUserCredential, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (userCredential?.id) {
            await dispatch(UserCredentialThunks.update(new UserCredential(updatedUserCredential))).then(() => closeDrawer());
        } else {
            await dispatch(UserCredentialThunks.create(new UserCredential(updatedUserCredential))).then(() => closeDrawer());
        }
    };
    const handleSelectChange = (value: string) => {
        setUpdatedUserCredential((prevState: Credential) => ({
            ...prevState,
            credentialId: value,
        }));
        setIsSaveDisabled(!value); //
    };

    const handleFileChange = (files: FileList | null) => {
        if (files && files.length > 0) {
            const attachment = files[0];
            setUploadedFile(attachment);
            setUpdatedUserCredential((prevState: UserCredential) => ({
                ...prevState,
                attachment: attachment,
            }));
        }
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        setUpdatedUserCredential({
            id: '',
            name: '',
            licensingAuthority: '',
            expirationDate: '',
            remindUserAt: '',
            credentialId: '',
            userId: '',
        })

        setUploadedFile(null);
        setIsSaveDisabled(true);
    };

    const handleDelete = () => {
        setUploadedFile(null);
        setUpdatedUserCredential((prevState: UserCredential) => ({
            ...prevState,
            attachment: null,
        }));
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div className="h-[158px] pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                    <div className=" mx-6 self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                            {userCredential?.id ? 'Edit credentials' : 'Create new credentials'}
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {userCredential?.id ? 'Modify an employee\'s credentials so you can keep track of their status and remain compliant.' : 'Add an employee\'s credentials so you can keep track of their status and remain compliant.'}
                        </div>
                    </div>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full ">
                                    {uploadedFile || userCredential?.attachmentUrl ? (
                                        <div
                                            className=" w-full h-auto  p-5 bg-white rounded-md border border-slate-200 flex-col justify-start items-start inline-flex">
                                            <div
                                                className="self-stretch flex-col justify-start items-start gap-2.5 flex">
                                                <div
                                                    className="self-stretch justify-start items-center gap-2 inline-flex">
                                                    <div
                                                        className="w-[48px] h-[48px] bg-white rounded-lg border border-slate-200 flex-col justify-center items-center inline-flex">
                                                        <div className="w-6 h-6 mt-1 ml-1 text-slate-400">
                                                            <FileTextIcon/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                        <div
                                                            className="self-stretch text-slate-800 text-base font-semibold font-['Inter'] leading-normal tracking-tight">
                                                            {uploadedFile?.name || userCredential?.name}
                                                        </div>
                                                        <div
                                                            className="self-stretch text-slate-400 text-xs font-medium font-['Inter'] leading-none tracking-tight">
                                                            {(uploadedFile && uploadedFile.size !== undefined) ?
                                                                `${(uploadedFile.size / 1024).toFixed(2)} KB` :
                                                                userCredential?.size ? `${(userCredential.size / 1024).toFixed(2)} KB` : userCredential.attachmentSize}
                                                        </div>
                                                    </div>
                                                    <div className="w-4 h-4 relative text-slate-400">
                                                        <button type="button" onClick={handleDelete}>
                                                            <TrashIcon/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <FileInput
                                            onFileChange={handleFileChange}
                                            label="Drop your files here or"
                                            labelBlue="browse"
                                            subtitle="Maximum size: 50MB"
                                        />
                                    )}
                                </div>

                                <div className="w-full">
                                    <div className="flex flex-col gap-[0.625rem] w-full">
                                        <label htmlFor="roleId"
                                               className="w-[487px] block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            Credentials
                                        </label>
                                        <CustomSelect
                                            value={updatedUserCredential?.credentialId}
                                            onChange={(selectedOption) => handleSelectChange(selectedOption.value)}
                                            options={credentials.map((credential: Credential) => ({
                                                value: credential.id,
                                                label: credential.name,
                                            }))}

                                        />
                                    </div>
                                </div>
                                <div className="w-full">
                                    <TextInput
                                        id="licensingAuthority"
                                        type="text"
                                        label="Licensing Authority"
                                        value={updatedUserCredential?.licensingAuthority}
                                        placeholder="Licensing Authority"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-4 w-full ">
                                    <DateInput
                                        id="expirationDate"
                                        label="Expiration date"
                                        type="date"
                                        value={updatedUserCredential?.expirationDate}
                                        onChange={handleChange}
                                        placeholder="MM/DD/YYYY"
                                    />
                                    <DateInput
                                        id="remindUserAt"
                                        label="Reminder"
                                        type="date"
                                        value={updatedUserCredential?.remindUserAt}
                                        onChange={handleChange}
                                        placeholder="MM/DD/YYYY"
                                        calendarAlignment={"left"}
                                    />
                                </div>
                                <div className="w-full">
                                    <DescriptionInput
                                        id="notes"
                                        type="text"
                                        label="Notes"
                                        value={updatedUserCredential?.notes}
                                        placeholder="Notes"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(userCredential?.id ? 'Save' : 'Add credential')}
                                        disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default UserCredentialForm;
