import React, {useState} from 'react';
import BrowseFileIcon from "../../assets/images/icons/BrowseFileIcon";
import {FileList} from "happy-dom";

interface FileInputProps {
    label?: string,
    onFileChange: (files: globalThis.FileList | null) => void;
    labelBlue?: string,
    subtitle?: string,
}

const FileInput: React.FC<FileInputProps> = ({
                                                 label = 'Drop your files here or',
                                                 onFileChange,
                                                 labelBlue,
                                                 subtitle,
                                             }) => {
    const [dragging, setDragging] = useState(false);

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onFileChange(e.dataTransfer.files as globalThis.FileList);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            onFileChange(files);
            e.target.value = '';
        }
    };

    return (
        <div className="h-[208.75px] w-full flex-col justify-start items-center inline-flex">
            <div
                className={`self-stretch h-[208.75px] pt-12 bg-white rounded-xl border-dashed border ${
                    dragging ? 'border-cyan-400' : 'border-slate-200'
                } flex-col justify-start items-center  flex`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <div className="w-[70px] h-[44.75px] relative">
                    <BrowseFileIcon/>
                </div>
                <div className="flex-col justify-start items-center mt-2 flex">
                    <div className="justify-center items-start gap-[5px] inline-flex">
                        <div className="text-slate-800 text-base font-medium leading-normal tracking-tight">
                            {label}
                        </div>

                        <div className="justify-start items-start flex">
                            <label className="text-center text-cyan-400 text-base font-semibold cursor-pointer">
                                {labelBlue}
                                <input
                                    type="file"
                                    className="hidden"
                                    onChange={handleFileChange}
                                />
                            </label>
                        </div>

                    </div>
                </div>
                <div
                    className="text-center text-gray-400 text-sm font-medium font-['Inter'] leading-tight tracking-tight">
                    {subtitle}
                </div>
            </div>
        </div>
    );
};

export default FileInput;
