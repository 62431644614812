    import apiClient from "services/apiClient";
    import {Credential} from "types/credential";
    import {SearchType} from "types/search";
    import {buildUrlWithParams} from "utils/apiUtils";
    
    export class CredentialService {
        static async index(
            page?: number,
            search?: SearchType<{ [key: string]: any }>,
            sortField?: string,
            sortDirection?: string,
            rowsPerPage?: number
        ) {
            const url = buildUrlWithParams({
                baseUrl: 'api/v1/credentials',
                page: page || undefined,
                search: search || undefined,
                sortField: sortField || undefined,
                sortDirection: sortDirection || undefined,
                rowsPerPage: rowsPerPage || undefined,
            });

            const response = await apiClient.get(url);
            return response.data;
        }
    
        static async create(record: Credential) {
            const response = await apiClient.post("api/v1/credentials", {credential: record.toJson()});
            return response.data;
        }
    
        static async show(id: string) {
            const response = await apiClient.get(`api/v1/credentials/${id}`);
            return response.data;
        }
    
        static async update(record: Credential) {
            const response = await apiClient.patch(`api/v1/credentials/${record.id}`, {credential: record.toJson()});
            return response.data;
        }
    
        static async delete(id: string) {
            const response = await apiClient.delete(`api/v1/credentials/${id}`);
            return response.data;
        }
    }
