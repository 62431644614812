import React, {useEffect, useRef, useState} from "react";
import {FiUsers} from "react-icons/fi";
import {logout} from "features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import Search from "assets/images/icons/Search";
import Settings from "assets/images/icons/Settings";
import Home from "assets/images/icons/Home";
import DotsMenuIcon from "assets/images/icons/DotsMenuIcon";
import {selectIsSidebarOpen, toggleSidebar} from "./sidebarSlice";
import ArrowRightLine from "assets/images/icons/ArrowRightLine";
import HorizontalDots from "assets/images/icons/HorizontalDots";
import SidebarItemCompressed from "features/sidebar/SidebarItemCompressed";
import Out from "assets/images/icons/Out";
import Profile from "assets/images/icons/Profile";
import {RootState} from "store/store";
import UsersCheck from "../../assets/images/icons/UsersCheck";

interface CompressedSidebarContentProps {
    setIsNewDrawerOpen: (isOpen: boolean) => void;
}

const CompressedSidebarContent: React.FC<CompressedSidebarContentProps> = ({ setIsNewDrawerOpen }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const isSidebarOpen = useSelector(selectIsSidebarOpen);

    const searchInputFocus = () => {
        const searchInput = document.getElementById("search-input");
        searchInput?.focus();
    };

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    }

    const handleToggleSidebarSearch = () => {
        dispatch(toggleSidebar());
        setTimeout(() => {
            searchInputFocus();
        }, 300);
    };

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target as Node)
        ) {
            setIsPopoverOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        dispatch(logout());
        window.location.href = "/login";
    };

    const handleMyProfile = () => {
        setIsNewDrawerOpen(true);
    };

    return (
        <aside
            id="hs-pro-sidebar"
            className={`sidebar-container hs-overlay [--auto-close:lg hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300
                  transform w-[84px] h-full ${!isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
                  ${!isSidebarOpen ? "block" : "hidden"} ${!isSidebarOpen ? "block" : "lg:hidden"} ${!isSidebarOpen ? "lg:end-auto lg:bottom-0" : ""}
                  fixed inset-y-0 start-0 z-[30] bg-white dark:bg-neutral-800 dark:border-neutral-700`}>

            <div className="flex flex-col z-30 h-full max-h-full pt-3 justify-between items-center overflow-y-auto">
                <div className="flex flex-col items-center">
                    <header className="h-[46px]"></header>
                    <div className="mt-1.5 h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                        <nav className="flex flex-col items-center px-4">
                            <ul className="w-full">
                                <li className="flex justify-center items-center py-2 px-3 text-slate-400 dark:text-neutral-300">
                                    <HorizontalDots/>
                                </li>
                                <li className="flex justify-center items-center py-2 px-3">
                                    <button className="flex justify-center items-center text-slate-600 rounded-lg hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700
                                                        focus:outline-none focus:bg-cyan-100 focus:text-slate-800 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700"
                                            onClick={handleToggleSidebarSearch}>
                                        <Search/>
                                    </button>
                                </li>
                                <li className="flex justify-center items-center py-2 px-3 text-slate-400 dark:text-neutral-300">
                                    <HorizontalDots/>
                                </li>
                                <li className="py-4 w-full flex flex-col items-center">
                                    <SidebarItemCompressed
                                        className="w-full flex justify-center items-center"
                                        id="users"
                                        Icon={Home}
                                    />
                                    <SidebarItemCompressed
                                        className="w-full flex justify-center items-center"
                                        id="users"
                                        Icon={Home}
                                    />
                                    <SidebarItemCompressed
                                        className="w-full flex justify-center items-center"
                                        id="users"
                                        Icon={Home}
                                    />
                                    <SidebarItemCompressed
                                        className="w-full flex justify-center items-center"
                                        id="users"
                                        Icon={Home}
                                    />
                                </li>
                                <li className="flex justify-center items-center py-2 px-3 text-slate-400 dark:text-neutral-300">
                                    <HorizontalDots/>
                                </li>
                                <li className="py-4 w-full">
                                    <div
                                        className="flex justify-center items-center w-full h-9 rounded-lg text-[13px] text-gray-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                        <SidebarItemCompressed
                                            className={"w-6 h-6 flex justify-center items-center"}
                                            id="users"
                                            Icon={FiUsers}
                                            Item={{href: "/users"}}
                                        />
                                    </div>
                                    <div
                                        className="flex justify-center items-center w-full h-9 rounded-lg text-[13px] text-gray-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                        <SidebarItemCompressed
                                            className={"w-6 h-6 flex justify-center items-center"}
                                            id="hr"
                                            Icon={UsersCheck}
                                            Item={{href: "/hr"}}
                                        />
                                    </div>
                                    <div
                                        className="flex justify-center items-center w-full h-9 rounded-lg text-[13px] text-gray-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                        <SidebarItemCompressed
                                            className={"w-6 h-6 flex justify-center items-center"}
                                            id="settings"
                                            Icon={Settings}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10">
                    <button
                        type="button"
                        className="w-6 h-7 inline-flex justify-center items-center text-sm font-medium
                            text-gray-400 hover:bg-cyan-50 focus:outline-none focus:bg-cyan-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800
                            dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                        onClick={handleToggleSidebar}
                    >
                        <ArrowRightLine/>
                    </button>
                </div>
                <footer className="hs-tooltip [--trigger:click] [--placement:right] inline-block relative">
                    <div className="flex items-center gap-x-2">
                        <div className="relative inline-flex w-full justify-center">
                            <div
                                className="hs-tooltip [--trigger:click] [--placement:right] inline-block pb-3 px-4 w-full text-slate-400">
                                <button
                                    id="popover-button"
                                    type="button"
                                    onClick={togglePopover}
                                    className="hs-tooltip-toggle w-full text-start flex items-center justify-between gap-x-3 py-2 px-3 text-sm text-slate-400 rounded-lg hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-neutral-700 focus:outline-none focus:bg-cyan-100 dark:text-neutral-300 dark:focus:bg-neutral-700">
                                    <DotsMenuIcon/>
                                </button>
                                {isPopoverOpen && (
                                    <div
                                        ref={popoverRef}
                                        className="absolute bottom-full mb-2 w-9 bg-white dark:bg-neutral-900 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] rounded-lg z-1000">
                                        <div className="p-1">
                                            <button
                                                key={"Profile"}
                                                type="button"
                                                onClick={handleMyProfile}
                                                className="flex justify-center items-center w-full h-9 rounded-lg text-[13px] text-gray-600 hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                <div className="w-6 h-6 flex justify-center items-center">
                                                    <Profile/>
                                                </div>
                                            </button>
                                            <div className="border-t border-gray-100 dark:border-neutral-700"/>
                                            <button
                                                key={"logout"}
                                                type="button"
                                                onClick={handleLogout}
                                                className="flex justify-center items-center w-full h-9 rounded-lg text-[13px] text-gray-600 hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                <div className="w-6 h-6 flex justify-center items-center">
                                                    <Out/>
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </aside>
    );
};
export default CompressedSidebarContent;
