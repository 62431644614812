import {createAsyncThunk} from '@reduxjs/toolkit';
import {UserService} from 'services/userService';
import {User} from "types/user";
import {SearchType} from "types/search";


export class UserThunks {
    static create = createAsyncThunk(
        'user/create',
        async ({user, password, token, pinCode, signature, credentials}: {
            user: User,
            password: string,
            token: string,
            pinCode: string,
            signature: string,
            credentials: any
        }) => {
            const response = await UserService.create(user, token, password, pinCode, signature, credentials);
            return {...response, userCredentials: {email: user.email, password}};
        }
    );
    static index = createAsyncThunk(
        'user/fetch',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any}>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await UserService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );
    static show = createAsyncThunk(
        'user/fetchById',
        async (id: string) => {
            return await UserService.show(id);
        }
    );
    static reactivate = createAsyncThunk(
        'user/activate',
        async (id: string) => {
            return await UserService.reactivate(id);
        }
    );
    static deactivate = createAsyncThunk(
        'user/deactivate',
        async (id: string) => {
            return await UserService.deactivate(id);
        }
    );
    static unlock = createAsyncThunk(
        'user/unlock',
        async (id: string) => {
            return await UserService.unlock(id);
        }
    );
    static unblock = createAsyncThunk(
        'user/unblock',
        async (id: string) => {
            return await UserService.unblock(id);
        }
    );
    static update = createAsyncThunk(
        'user/update',
        async (user: User) => {
            return await UserService.update(user);
        }
    );
}

