import {createAsyncThunk} from "@reduxjs/toolkit";
import {UserInvitation} from "types/userInvitation";
import {UserInvitationService} from "services/userInvitationService";
import {SearchType} from "types/search";

export class UserInvitationThunks {
    static create = createAsyncThunk(
        'userInvitation/create',
        async (invitationData: UserInvitation, {rejectWithValue}) => {
            try {
                const response = await UserInvitationService.create(invitationData);
                return response;
            } catch (error) {
                if (error instanceof Error) {
                    return rejectWithValue(error.message);
                }
                return rejectWithValue('An error occurred');
            }
        }
    );
    static cancel = createAsyncThunk(
        'userInvitation/cancel',
        async (id: string, {rejectWithValue}) => {
            try {
                const response = await UserInvitationService.cancel(id);
                return response;
            } catch (error) {
                if (error instanceof Error) {
                    return rejectWithValue(error.message);
                }
                return rejectWithValue('An error occurred');
            }
        }
    );
    static showByToken = createAsyncThunk(
        'userInvitation/getByToken',
        async (token: string, {rejectWithValue}) => {
            return await UserInvitationService.showByToken(token);
        }
    );
    static index = createAsyncThunk(
        'userInvitation/fetch',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{email: string}>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await UserInvitationService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );
    static show = createAsyncThunk(
        'userInvitation/fetchById',
        async (id: string) => {
            return await UserInvitationService.show(id);
        }
    );
}

