import React, {useEffect, useState} from 'react';
import SubmitButton from "components/SubmitButton";
import ArrowRight from "assets/images/icons/ArrowRight";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {useNavigate, useParams} from "react-router-dom";
import {setCredentials} from "features/auth/authSlice";
import DateInput from "components/inputs/DateInput";
import {CredentialThunks} from "features/credential/credentialThunks";
import ArrowLeft from "assets/images/icons/ArrowLeft";


const AddCredentialsPage: React.FC = () => {
    const status = useSelector((state: RootState) => state.auth.status);
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const credentials = useSelector((state: RootState) => state.credential.credentials);
    const dispatch = useDispatch<AppDispatch>();
    const [credentialsIds, setCredentialsIds] = useState<string[]>([]);
    const [formData, setFormData] = useState({
        credentials: {} as any,
    });
    const saveCredentials = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(setCredentials(formData.credentials));
        navigate(`/signature/${params.token}`);
    }
    useEffect(() => {
        const allFilled = credentialsIds.every((id) => formData.credentials[id] && formData.credentials[id] !== '');
        setIsFormValid(allFilled);
    }, [formData, credentialsIds]);

    useEffect(() => {
        const fetchCredentials = async () => {
            await dispatch(CredentialThunks.index());
        }
        if (credentials.length === 0) {
            fetchCredentials();
        }
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const credentialIdsParam = searchParams.get('credentialIds');
        if (credentialIdsParam) {
            const ids = credentialIdsParam.split(',');
            setCredentialsIds(ids);
        }
    }, []);
    const getCredentialNameById = (id: string) => {
        const credential = credentials.find((credential: Credential) => credential.id.toString() === id);
        return credential ? credential.name : '';
    }


    return (
        <div className="flex flex-col justify-between">
            <div className="flex-grow flex flex-col md:pr-20 md:pl-20 pt-20 max-w-120 md:min-w-96 sm:min-w-64">
                <div className="w-80">
                    <div className="w-80 text-xl font-semibold tracking-normal text-slate-800">
                        Your credentials
                    </div>
                    <div
                        className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                        <span className="leading-tight">  To ensure and maintain compliance, please add the expiration date of your credentials below. </span>
                    </div>
                    <form className="mt-12 needs-validation self-stretch w-80">
                        <div className="flex flex-col space-y-6 w-80">
                            {credentialsIds.map((id) => (

                                <DateInput
                                    label={`When does your ${getCredentialNameById(id)} expire?`}
                                    type={'date'}
                                    value={formData.credentials[id]}
                                    onChange={(e) => setFormData({
                                        ...formData,
                                        credentials: {...formData.credentials, [id]: e.target.value}
                                    })}
                                    placeholder="MM/DD/YYYY"
                                    id={'expirationDate'}
                                    className={"w-80"}

                                />
                            ))}
                        </div>
                    </form>
                </div>
            </div>
            <footer className="grid grid-rows-2 justify-center w-full mt-8">
                <div className="flex justify-center items-center w-full gap-3">
                    <button
                        onClick={() => navigate(-1)}
                        className="flex items-center py-3 px-2.5  text-cyan-500 border border-cyan-400 rounded-lg hover:bg-cyan-50 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                    >
                                <span className="mx-1">
                                    <ArrowLeft/>
                                </span>
                    </button>
                    <SubmitButton
                        className={"w-[330px] py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"}
                        onClick={saveCredentials}
                        label={<>{'Continue'} <ArrowRight/></>}
                        disabled={status === 'loading' || !isFormValid}
                    />
                </div>
                <div className="flex justify-center items-center ">
                    <div className="flex items-center gap-3">
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-cyan-400 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                        <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default AddCredentialsPage;
