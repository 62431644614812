import React, { } from 'react';
import backgroundLogIn from "assets/images/backgroundLogIn.png";
import ForgotPassword from "features/auth/components/ForgotPassword";
import {Link} from 'react-router-dom';
import AuthLayout from "layouts/AuthLayout";

const ForgotPasswordPage : React.FC = () => {
    return (
        <div>
            <div className="flex flex-col pr-20 pl-20 pt-20  max-w-120 md:min-w-96 sm:min-w-64">
                <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
                    Forgot your password?
                </div>
                <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                    No problem. We can help
                </div>
                <ForgotPassword/>

                <Link
                    to={'/login'}
                    className="self-center mt-6 mb-6 text-sm font-light tracking-normal leading-5 text-center underline text-zinc-400 hover:text-cyan-400  "
                    style={{cursor: 'pointer'}}>
                    Back to sign in
                </Link>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
