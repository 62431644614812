      import apiClient from "services/apiClient";
      import {Location} from "types/location";
      import {SearchType} from "../types/search";
      import {buildUrlWithParams} from "../utils/apiUtils";
      
      export class LocationService {
          static async index(
              page?: number,
              search?: SearchType<{ [key: string]: any }>,
              sortField?: string,
              sortDirection?: string,
              rowsPerPage?: number
          ) {
              const url = buildUrlWithParams({
                  baseUrl: 'api/v1/locations',
                  page: page || undefined,  // Opcional
                  search: search || undefined,  // Opcional
                  sortField: sortField || undefined,  // Opcional
                  sortDirection: sortDirection || undefined,  // Opcional
                  rowsPerPage: rowsPerPage || undefined,  // Opcional
              });

              const response = await apiClient.get(url);
              return response.data;
          }

          static async reactivate(id: string) {
              const response = await apiClient.patch(`api/v1/locations/${id}/reactivate`);
              return response.data;
          }
          static async deactivate(id: string) {
              const response = await apiClient.patch(`api/v1/locations/${id}/deactivate`);
              return response.data;
          }
      
          static async create(record: Location) {
              const response = await apiClient.post("api/v1/locations", {location: record.toJson()});
              return response.data;
          }
      
          static async show(id: string) {
              const response = await apiClient.get(`api/v1/locations/${id}`);
              return response.data;
          }
      
          static async update(record: Location) {
              const response = await apiClient.patch(`api/v1/locations/${record.id}`, {location: record.toJson()});
              return response.data;
          }
      
          static async delete(id: string) {
              const response = await apiClient.delete(`api/v1/locations/${id}`);
              return response.data;
          }
      }
