import apiClient from "services/apiClient";
import {Role} from "types/role";
import {SearchType} from "../types/search";
import {buildUrlWithParams} from "../utils/apiUtils";


export class RoleService {
    static async index(
        page?: number,
        search?: SearchType<{ [key: string]: any }>,
        sortField?: string,
        sortDirection?: string,
        rowsPerPage?: number
    ) {
        const url = buildUrlWithParams({
            baseUrl: 'api/v1/roles',
            page: page || undefined,  // Opcional
            search: search || undefined,  // Opcional
            sortField: sortField || undefined,  // Opcional
            sortDirection: sortDirection || undefined,  // Opcional
            rowsPerPage: rowsPerPage || undefined,  // Opcional
        });

        const response = await apiClient.get(url);
        return response.data;
    }
    static async create(role: Role) {
        const response = await apiClient.post("api/v1/roles", {role: role.toJson()});
        return response.data;
    }
    static async selectable() {
        const response = await apiClient.get("api/v1/roles/index_with_users");
        return response.data;
    }
    static async show (id: string) {
        const response = await apiClient.get(`api/v1/roles/${id}`);
        return response.data;
    }
    static async update(role: Role) {
        const response = await apiClient.patch(`api/v1/roles/${role.id}`, {role: role.toJson()});
        return response.data;
    }



}

