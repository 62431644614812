import React, {createContext, useContext, useState, ReactNode} from "react";

interface SortingContextProps {
  sortField: string;
  sortDirection: "asc" | "desc";
  setSortField: (field: string) => void;
  setSortDirection: (direction: "asc" | "desc") => void;
}

const SortingContext = createContext<SortingContextProps | undefined>(
  undefined,
);

export const SortingProvider: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  const [sortField, setSortField] = useState<string>("id");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  return (
    <SortingContext.Provider
      value={{sortField, sortDirection, setSortField, setSortDirection}}>
      {children}
    </SortingContext.Provider>
  );
};

export const useSorting = () => {
  const context = useContext(SortingContext);
  if (context === undefined) {
    throw new Error("useSorting must be used within a SortingProvider");
  }
  return context;
};
