export interface PermissionInterface {
    id: string;
    action: string;
    subjectClass: string;
    _destroy?: boolean;
}

export class Permission implements PermissionInterface {
    id: string;
    action: string;
    subjectClass: string;
    _destroy: boolean;

    constructor(data: Partial<PermissionInterface>) {
        this.id = data.id!;
        this.action = data.action!;
        this.subjectClass = data.subjectClass!;
        this._destroy = data._destroy || false;
    }

    toJson(): PermissionInterface {
        return {
            id: this.id,
            action: this.action,
            subjectClass: this.subjectClass,
            _destroy: this._destroy
        };
    }
}
