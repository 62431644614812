import React, {useEffect, useState} from 'react';
import useAppDispatch from "hooks/useAppDispatch";
import {useNavigate} from 'react-router-dom';
import {logout} from "features/auth/authSlice";
import {validateEmail, validatePassword} from "utils/validators";
import TextInput from "components/inputs/TextInput";
import PasswordInput from "components/inputs/PasswordInput";
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import SubmitButton from "components/SubmitButton";
import {AuthThunks} from "features/auth/authThunks";


const Login: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const status = useSelector((state: RootState) => state.auth.status);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        const isEmailValid = validateEmail(email);
        const isPasswordValid = validatePassword(password);
        setIsFormValid(isEmailValid && isPasswordValid && email !== '' && password !== '');
    }, [email, password]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);

        if (validateEmail(value)) {
            setEmailError('');
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);

        if (validatePassword(value)) {
            setPasswordError(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const resultAction = await dispatch(AuthThunks.login({email: email.trim(), password}));
        if (AuthThunks.login.fulfilled.match(resultAction)) {
            navigate('/second-step-verification');
        }
    };

    return (
        <form className="mt-12 needs-validation self-stretch w-full" onSubmit={handleSubmit} noValidate >
            <div className="flex flex-col space-y-6">
                <TextInput
                    id="email"
                    type="text"
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    placeholder="you@email.com"
                    tabIndex={1}
                />
                <div>
                    <PasswordInput
                        label={'Password'}
                        password={password}
                        onPasswordChange={handlePasswordChange}
                        passwordError={passwordError}
                        showPassword={showPassword}
                        onToggleShowPassword={() => setShowPassword(!showPassword)}
                        inputId={'password'}
                    />
                    <div className="mt-4">
                    <SubmitButton
                        onClick={handleSubmit}
                        label="Sign in"
                        disabled={status === 'loading' || !isFormValid}
                    />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Login;
