import React, {useEffect, useState} from "react";
import UserCredentialTable from "features/hr/UserCredentialTable";
import Drawer from "components/Drawer";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {User} from "types/user";
import CheckCircle from "assets/images/icons/CheckCircle";
import XCircle from "assets/images/icons/XCircle";
import Blocked from "assets/images/icons/Blocked";
import Locked from "assets/images/icons/Locked";
import {UserThunks} from "features/user/userThunk";
import {useParams} from "react-router-dom";
import UserCredentialForm from "features/hr/UserCredentialForm";
import CircleDashedIcon from "../../../assets/images/icons/CircleDashedIcon";
import UserTrainingsTable from "../../../features/hr/UserTrainingsTable";
import HRDocumentsTable from "../../../features/hr/HRDocumentsTable";

const HrUserPage: React.FC = () => {
    const activeTab = useSelector((state: RootState) => state.hr.activeTab);
    const user = useSelector((state: RootState) => state.user.user as User);
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    useEffect(() => {
        dispatch(UserThunks.show(params.userId as string));
    }, []);
    return (

        <div className="relative overflow-hidden">
            {user && (
            <div className="pb-6">
                <div className="flex items-center space-x-4">
                    <div className="text-slate-600 text-lg font-medium font-['Inter']">
                        {user?.firstName} {user?.lastName}
                    </div>
                    <span
                        className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-[123px]
                            ${user.status === 'Active' && !user.locked && !user.blocked ? 'bg-green-100 text-green-800' : ''}
                            ${user.status === 'Inactive' && !user.locked && !user.blocked ? 'bg-red-100 text-red-800' : ''}
                            ${user.status === 'Onboarding' && !user.locked && !user.blocked ? 'bg-yellow-100 text-yellow-800' : ''}
                            ${user.locked ? 'bg-gray-100 text-gray-700' : ''}
                            ${user.blocked ? 'bg-gray-100 text-gray-700' : ''}
                        `}
                    >
                        {user.status === 'Active' && !user.locked && !user.blocked && <CheckCircle className="mr-2"/>}
                        {user.status === 'Inactive' && !user.locked && !user.blocked && <XCircle className="mr-2"/>}
                        {user.status === 'Onboarding' && !user.locked && !user.blocked && <CircleDashedIcon className="mr-2"/>}
                        {user.blocked && <Blocked className="mr-2"/>}
                        {user.locked && <Locked className="mr-2"/>}
                        <div className="pl-1">
                            {user.status === 'Active' && !user.locked && !user.blocked && user.status}
                            {user.status === 'Inactive' && !user.locked && !user.blocked && 'Inactive'}
                            {user.status === 'Onboarding' && !user.locked && !user.blocked && 'Onboarding'}
                            {user.blocked && 'Blocked'}
                            {user.locked && 'Locked'}
                        </div>
                     </span>
                </div>
                <div
                    className="flex items-center space-x-2 text-slate-400 text-sm font-light font-['Inter'] leading-normal">
                    <div>{user?.role?.nameAlias}</div>
                    <div>|</div>
                    <div>ID: {user?.id}</div>
                </div>
            </div>)}
            {activeTab === 'HR Documents' && (
                <div>
                    <HRDocumentsTable openDrawer={ openDrawer } />
                </div>
            )}
            {activeTab === 'Credentials' && (
            <div>
                <UserCredentialTable openDrawer={openDrawer} />
            <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                <UserCredentialForm closeDrawer={closeDrawer} isDrawerOpen={isDrawerOpen} />
            </Drawer>
            </div>
            )}

            {activeTab === 'Trainings' && (
                <div>
                    <UserTrainingsTable openDrawer={ openDrawer } />

                </div>
            )}

        </div>
    );
};

export default HrUserPage;
