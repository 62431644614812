export function toSnakeCase(obj: any): any {
    if (obj instanceof File || obj instanceof Blob) {
        return obj;
    }

    if (obj instanceof Array) {
        return obj.map((value) => toSnakeCase(value));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((result, key) => {
            const newKey = toSnakeCaseKey(key);
            result[newKey] = toSnakeCase(obj[key]);
            return result;
        }, {} as any);
    }
    return obj;
}

function toSnakeCaseKey(key: string): string {
    return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}
export function toCamelCase(obj: any): any {
    if (obj instanceof Array) {
        return obj.map((value) => toCamelCase(value));
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((result, key) => {
            const newKey = key.replace(/(_\w)/g, (k) => k[1].toUpperCase());
            result[newKey] = toCamelCase(obj[key]);
            return result;
        }, {} as any);
    }
    return obj;
}

export function toSnakeCaseString(str: string): string {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}


export function formatSSN  (value: string)  {
    const ssn = value.replace(/\D/g, '');
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
}


export function formatPhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) return "";

    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber;
};
