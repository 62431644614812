import React, {useEffect, useState} from "react";
import Table from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {UserCredentialThunks} from "features/userCredential/userCredentialThunks";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";
import {SearchType} from "types/search";
import {useParams} from "react-router-dom";
import {setActiveTab} from "features/hr/hrSlice"
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";



const UserHRDocumentsTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const refresh = useSelector((state: RootState) => state.userCredential.refresh);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.userCredential.pagy);
    const params = useParams<{userId: string}>();
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    interface VisibleColumnsType {
        name: boolean;
        status: boolean;
        expDate: boolean;
        notes: boolean;
        completedBy: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        status: true,
        expDate: true,
        completedBy: true,
        notes: true
    });

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];

    const columnOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
        {label: 'Status', value: 'status', operator: 'like'},
        {label: 'Exp Date', value: 'expDate', operator: 'like'},
        {label: 'Completed By', value: 'completedBy', operator: 'like'},
        {label: 'Notes', value: 'notes', operator: 'like'},

    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        const { userId } = params;

        search.where.userId = {
            operator: '==',
            value: userId as string
        };

        await dispatch(UserCredentialThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenNewHRDocumentDrawer = () => {
        openDrawer();
    };


    useEffect(() => {
        fetchData({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10});
    }, [refresh]);




    return (
        <Table
            tabs={['HR Documents', 'Credentials', 'Trainings', 'Evaluations']}
            activeTab={'HR Documents'}
            fetchData={fetchData}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            pagy={pagy}
            recordsName={'HR Documents'}
            columns={true}
            blueButton={<BlueButton onClick={handleOpenNewHRDocumentDrawer} label={''} icon={<Plus/>}/>}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.expDate && <TableHeader label="Exp Date" sortBy="expDate"/>}
                {visibleColumns.completedBy && <TableHeader label="Complete by" sortBy="completeBy" />}
                {visibleColumns.notes && <TableHeader label="Notes" sortBy="notes"/>}
                <TableHeader label=""/>
            </tr>
            </thead>

        </Table>
    );
};

export default UserHRDocumentsTable;
