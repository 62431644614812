import {createAsyncThunk} from "@reduxjs/toolkit";
import {Role} from "types/role";
import {RoleService} from "services/roleService";
import {SearchType} from "../../types/search";

export class RoleThunks {
    static index = createAsyncThunk(
        'role/index',
        async ({
                   page,
                   search,
                   sortField,
                   sortDirection,
                   rowsPerPage
               }: {
            page?: number,
            search?: SearchType<{ [key: string]: any }>,
            sortField?: string,
            sortDirection?: string,
            rowsPerPage?: number
        } = {}) => {
            return await RoleService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );
    static create = createAsyncThunk(
        'role/create',
        async (role: Role) => {
            return await RoleService.create(role);
        }
    );
    static selectable = createAsyncThunk(
        'role/selectable',
        async () => {
            return await RoleService.selectable();
        }
    );
    static show = createAsyncThunk(
        'role/show',
        async (id: string) => {
            return await RoleService.show(id);
        }
    );
    static update = createAsyncThunk(
        'role/update',
        async (role: Role) => {
            return await RoleService.update(role);
        }
    );

}


