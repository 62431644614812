export interface PayerInterface {
    id: string;
    name: string;
    address: string;
    payerType: string;
    phoneNumber: string;
    externalId: string;
    status: string;
}

export class Payer implements PayerInterface {
    id: string;
    name: string;
    address: string;
    payerType: string;
    phoneNumber: string;
    externalId: string;
    status: string;

    constructor(data: Partial<PayerInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.address = data.address!;
        this.payerType = data.payerType!;
        this.phoneNumber = data.phoneNumber!;
        this.externalId = data.externalId!;
        this.status = data.status!;
    }

    toJson(): PayerInterface {
        return {
            id: this.id,
            name: this.name,
            address: this.address,
            payerType: this.payerType,
            phoneNumber: this.phoneNumber,
            externalId: this.externalId,
            status: this.status
        };
    }
}
