import React from 'react';

export const Notification = () => (

    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="bell">
            <path id="Vector"
                  d="M4 5.33334C4 4.27248 4.42143 3.25506 5.17157 2.50492C5.92172 1.75477 6.93913 1.33334 8 1.33334C9.06087 1.33334 10.0783 1.75477 10.8284 2.50492C11.5786 3.25506 12 4.27248 12 5.33334C12 10 14 11.3333 14 11.3333H2C2 11.3333 4 10 4 5.33334Z"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path id="Vector_2"
                  d="M6.86664 14C6.97823 14.203 7.14227 14.3722 7.34163 14.4901C7.54099 14.608 7.76836 14.6702 7.99997 14.6702C8.23159 14.6702 8.45895 14.608 8.65831 14.4901C8.85767 14.3722 9.02172 14.203 9.1333 14"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </svg>
);
export default Notification;
