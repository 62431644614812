import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Credential} from "types/credential";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {CredentialThunks} from "./credentialThunks";
import {setIsEditing, setCredential} from "features/credential/credentialSlice";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";

const CredentialTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const credentials = useSelector((state: RootState) => state.credential.credentials);
    const refresh = useSelector((state: RootState) => state.credential.refresh);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.credential.pagy);

    const [visibleColumns, setVisibleColumns] = useState({
        id: true,
        name: true,
        description: true,
        expires: true,
        remindHr: true,
        hrReminderDays: true,
        remindUser: true,
        userReminderDays: true,
        requiresTraining: true,
        trainingLinks: true
    });

    interface VisibleColumnsType {
        id: boolean,
        name: boolean,
        description: boolean,
        expires: boolean,
        remindHr: boolean,
        hrReminderDays: boolean,
        remindUser: boolean,
        userReminderDays: boolean,
        requiresTraining: boolean,
        trainingLinks: boolean
    }

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        await dispatch(CredentialThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };

    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(CredentialThunks.show(id));
    };

    const handleOpenNewCredentialDrawer = () => {
        dispatch(setCredential({
            id: '',
            name: '',
            description: '',
            expires: '',
            remindHr: '',
            hrReminderDays: '',
            remindUser: '',
            userReminderDays: '',
            requiresTraining: '',
            trainingLinks: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };

    useEffect(() => {
        fetchData({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10});
    }, [refresh]);

    const searchByOptions = [
        {label: 'ID', value: 'id', operator: '=='},
    ];

    const columnOptions = [
        {label: 'Id', value: 'id', operator: 'like'},
        {label: 'Name', value: 'name', operator: 'like'},
        {label: 'Description', value: 'description', operator: 'like'},
        {label: 'Expires', value: 'expires', operator: 'like'},
        {label: 'Remindhr', value: 'remindHr', operator: 'like'},
        {label: 'Hrreminderdays', value: 'hrReminderDays', operator: 'like'},
        {label: 'Reminduser', value: 'remindUser', operator: 'like'},
        {label: 'Userreminderdays', value: 'userReminderDays', operator: 'like'},
        {label: 'Requirestraining', value: 'requiresTraining', operator: 'like'},
        {label: 'Traininglinks', value: 'trainingLinks', operator: 'like'}
    ];

    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            recordsName={'Credentials'}
            searchable={true}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={handleOpenNewCredentialDrawer} label="" icon={<Plus/>}/>}
        >
            <thead>
            <tr>
                {visibleColumns.id && <TableHeader label="Id"/>}
                {visibleColumns.name && <TableHeader label="Name"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.expires && <TableHeader label="Expires"/>}
                {visibleColumns.remindHr && <TableHeader label="Remindhr"/>}
                {visibleColumns.hrReminderDays && <TableHeader label="Hrreminderdays"/>}
                {visibleColumns.remindUser && <TableHeader label="Reminduser"/>}
                {visibleColumns.userReminderDays && <TableHeader label="Userreminderdays"/>}
                {visibleColumns.requiresTraining && <TableHeader label="Requirestraining"/>}
                {visibleColumns.trainingLinks && <TableHeader label="Traininglinks"/>}
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {credentials.map((credential: Credential) => (
                <TableRow
                    key={credential.id}
                    onClick={handleOpenDrawer(credential.id)}
                    className="cursor-pointer hover:bg-cyan-50 focus:bg-cyan-100 dark:hover:bg-neutral-700 dark:text-neutral-300"
                >
                    {visibleColumns.id && <TableCell>{credential.id}</TableCell>}
                    {visibleColumns.name && <TableCell>{credential.name}</TableCell>}
                    {visibleColumns.description && <TableCell>{credential.description}</TableCell>}
                    {visibleColumns.expires && <TableCell>{credential.expires}</TableCell>}
                    {visibleColumns.remindHr && <TableCell>{credential.remindHr}</TableCell>}
                    {visibleColumns.hrReminderDays && <TableCell>{credential.hrReminderDays}</TableCell>}
                    {visibleColumns.remindUser && <TableCell>{credential.remindUser}</TableCell>}
                    {visibleColumns.userReminderDays && <TableCell>{credential.userReminderDays}</TableCell>}
                    {visibleColumns.requiresTraining && <TableCell>{credential.requiresTraining}</TableCell>}
                    {visibleColumns.trainingLinks && <TableCell>{credential.trainingLinks}</TableCell>}
                </TableRow>
            ))}
            </tbody>
        </Table>
    );
};

export default CredentialTable;
