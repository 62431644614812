import React, {useEffect, useRef, useState} from "react";
import LogoNewHope from "assets/images/LogoNewHope.png";
import { useNavigate} from "react-router-dom";
import {logout} from "features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import Search from "assets/images/icons/Search";
import BarsMenu from "assets/images/icons/BarsMenu";
import Notification from "assets/images/icons/Notification";
import Activity from "assets/images/icons/Activity";
import Settings from "assets/images/icons/Settings";
import SidebarItem from "features/sidebar/SidebarItems";
import Home from "assets/images/icons/Home";
import DotsMenuIcon from "assets/images/icons/DotsMenuIcon";
import {RootState} from "store/store";
import ArrowLeftLine from "assets/images/icons/ArrowLeftLine";
import {selectIsSidebarOpen, toggleSidebar} from "./sidebarSlice";
import UserIcon from "assets/images/icons/UserIcon";
import {setIsEditing, setUser} from "features/user/userSlice";
import UsersCheck from "assets/images/icons/UsersCheck";

interface SidebarContentProps {
    setIsNewDrawerOpen: (isOpen: boolean) => void;
}

const SidebarContent: React.FC<SidebarContentProps> = ({ setIsNewDrawerOpen }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    const isSidebarOpen = useSelector(selectIsSidebarOpen);
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selectedSubItemId, setSelectedSubItemId] = useState<string | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleItemClick = (id: string) => {
        setSelectedItemId(id);
        setSelectedSubItemId(null);
    };

    const handleSubItemClick = (id: string) => {
        setSelectedSubItemId(id);
    }

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    const togglePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target as Node)
        ) {
            setIsPopoverOpen(false);
        }
    };


    const handleLogout = () => {
        dispatch(logout());
        window.location.href = "/login";
    };

    const handleMyProfile = () => {
        dispatch(setUser(user));
        setIsEditing(false);
        togglePopover();
        setIsNewDrawerOpen(true);
    }

    return (
        <aside
            id="hs-pro-sidebar"
            className={`hs-overlay [--auto-close:lg]
                   hs-overlay-open:translate-x-0
                   -translate-x-full transition-all duration-300 transform
                   w-52 h-full
                   ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"}
                   ${isSidebarOpen ? "block" : "hidden"}
                   ${isSidebarOpen ? "lg:block" : "lg:hidden"}
                   ${isSidebarOpen ? "lg:end-auto lg:bottom-0" : ""}
                   fixed inset-y-0 start-0 z-[30]
                   bg-white
                   dark:bg-neutral-800 dark:border-neutral-700`}>
            <div className="flex flex-col z-30 gap-4 h-full max-h-full pt-3 justify-between overflow-y-auto">
                <div>
                    <header className="h-[46px] px-4">
                        <a className="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80 ">
                            <img
                                className="mt-2 w-28 h-auto"
                                src={LogoNewHope}
                                alt="Logo"
                                onClick={() => navigate("/home")}
                            />
                        </a>
                    </header>
                    <div className=" pt-4">
                        <div className="relative px-3  ">
                            <div
                                className="absolute inset-y-0 start-0 flex items-center pointer-events-none z-10 ps-6">
                                <Search/>
                            </div>
                            <input
                                type="text"
                                id="search-input"
                                ref={searchInputRef}
                                className=" py-[7px] px-3 ps-10 flex w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:border-cyan-400 focus:outline-cyan-400
                                               focus:ring-cyan-400 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:border-transparent dark:text-neutral-400
                                               dark:placeholder:text-neutral-400 dark:focus:ring-neutral-600"
                                placeholder=""
                            />
                        </div>
                    </div>
                    <nav
                        className="relative mx-3 pt-4 grid grid-cols-3 space-x-1 after:absolute after:bottom-0 after:inset-x-0 after:border-b-2 after:border-gray-200 dark:after:border-neutral-700"
                        aria-label="Tabs"
                        role="tablist">
                        <button
                            type="button"
                            className={`hs-tab-active:after:bg-slate-400 hs-tab-active:text-slate-400 px-2.5 py-1.5 mb-2 relative inline-flex justify-center 
                                           items-center gap-x-2  text-gray-300 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 
                                           disabled:pointer-events-none focus:outline-none after:absolute after:-bottom-2 after:inset-x-0
                                           after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 
                                           dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 active`}
                            id={`hs-pro-tabs-dut-item-Menu`}
                            data-hs-tab={`#hs-pro-tabs-dut-Menu`}
                            aria-controls={`hs-pro-tabs-dut-Menu`}
                            role="tab">
                            <BarsMenu/>
                        </button>
                        <button
                            type="button"
                            className={`hs-tab-active:after:bg-slate-400 hs-tab-active:text-slate-400 px-2.5 py-1.5 mb-2 relative inline-flex justify-center 
                                           items-center gap-x-2  text-gray-300 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 
                                           disabled:pointer-events-none focus:outline-none after:absolute after:-bottom-2 after:inset-x-0
                                           after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 
                                           dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 `}
                            id={`hs-pro-tabs-dut-item-Notifications`}
                            data-hs-tab={`#hs-pro-tabs-dut-Notifications`}
                            aria-controls={`hs-pro-tabs-dut-Notifications`}
                            role="tab">
                            <Notification/>
                        </button>
                        <button
                            type="button"
                            className={`hs-tab-active:after:bg-slate-400 hs-tab-active:text-slate-400 px-2.5 py-1.5 mb-2 relative inline-flex justify-center 
                                           items-center gap-x-2  text-gray-300 hover:text-gray-800 text-sm rounded-lg disabled:opacity-50 
                                           disabled:pointer-events-none focus:outline-none  after:absolute after:-bottom-2 after:inset-x-0
                                           after:z-10 after:h-0.5 after:pointer-events-none dark:hs-tab-active:text-neutral-200 dark:hs-tab-active:after:bg-neutral-400 
                                           dark:text-neutral-500 dark:hover:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700`}
                            id={`hs-pro-tabs-dut-item-Activity`}
                            data-hs-tab={`#hs-pro-tabs-dut-Activity`}
                            aria-controls={`hs-pro-tabs-dut-Activity`}
                            role="tab">
                            <Activity/>
                        </button>
                    </nav>
                    <div id="hs-pro-tabs-dut-Menu">
                        <div
                            className="mt-1.5 h-full overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                            <nav
                                className="hs-accordion-group pb-3 px-4 w-full flex flex-col flex-wrap"
                                data-hs-accordion-always-open>
                                <ul>
                                    <div className=" py-4 ">
                                        <SidebarItem
                                            isSelected={selectedItemId === "home"}
                                            onClick={handleItemClick}
                                            Item={{ href: "/home" }}
                                            id="users"
                                            label="Nav item one"
                                            Icon={Home}
                                        />
                                        <SidebarItem
                                            id="users"
                                            label="Nav item two"
                                            Icon={Home}
                                            isSelected={selectedItemId === "home"}
                                            onClick={handleItemClick}
                                            Item={{ href: "/home" }}
                                        />
                                        <SidebarItem
                                            id="users"
                                            label="Nav item three"
                                            Icon={Home}
                                            isSelected={selectedItemId === "home"}
                                            onClick={handleItemClick}
                                            Item={{ href: "/home" }}
                                        />
                                        <SidebarItem
                                            id="users"
                                            label="Nav item four"
                                            Icon={Home}
                                            isSelected={selectedItemId === "home"}
                                            onClick={handleItemClick}
                                            Item={{ href: "/home" }}
                                        />
                                    </div>

                                    <div
                                        className="text-slate-400 pt-2 text-xs font-normal font-['Inter'] leading-none tracking-tight">
                                        MANAGE
                                    </div>
                                    <div className=" py-4 ">
                                        <SidebarItem
                                            id="users"
                                            label="Users"
                                            Icon={UserIcon}
                                            Item={{href: "/users"}}
                                            isSelected={selectedItemId === "users"}
                                            onClick={handleItemClick}
                                        />
                                        <SidebarItem
                                            id="hr"
                                            label="Human Resources"
                                            Icon={UsersCheck}
                                            Item={{href: "/hr"}}
                                            isSelected={selectedItemId === "hr"}
                                            onClick={handleItemClick}
                                        />
                                        <SidebarItem
                                            id="settings"
                                            label="Settings"
                                            Icon={Settings}
                                            isSelected={selectedItemId === "settings"}
                                            onClick={handleItemClick}
                                            subItems={[
                                                { label: "Roles", href: "/role" },
                                                { label: "Credentials", href: "/credential" },
                                                { label: "Locations", href: "/location" },
                                                { label: "Programs", href: "/program" },
                                                { label: "Payers", href: "/payer" }
                                            ]}
                                            selectedSubItemId={selectedSubItemId || undefined}
                                            onSubItemClick={handleSubItemClick}
                                        />

                                    </div>

                                    <div
                                        className="text-slate-400 pt-6 text-xs border-t border-t-gray-200 font-normal font-['Inter'] leading-none tracking-tight">
                                        FAVORITES
                                        <div className=" py-4 ">
                                            <SidebarItem id="users" label="Nav item two " onClick={handleItemClick}/>
                                            <SidebarItem id="users" label="Nav item three" onClick={handleItemClick}/>
                                            <SidebarItem id="users" label="Nav item four"  onClick={handleItemClick} />
                                        </div>
                                    </div>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div id="hs-pro-tabs-dut-Notifications"></div>
                    <div id="hs-pro-tabs-dut-Activity"></div>

                    <div className="absolute top-4 end-0 mr-4 z-10">
                        <button
                            type="button"
                            className="w-6 h-7 inline-flex justify-center items-center  text-sm font-medium
                                       text-gray-300 hover:bg-cyan-50 focus:outline-none
                                       focus:bg-cyan-100 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800
                                       dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                            onClick={handleToggleSidebar}
                        >
                            <ArrowLeftLine/>
                        </button>
                    </div>
                </div>
                <footer className="hs-tooltip [--trigger:click] [--placement:right] inline-block relative">
                    <div className="flex items-center gap-x-2">
                        <div className="relative inline-flex w-full justify-end">
                            <div
                                className="hs-tooltip [--trigger:click] [--placement:right] inline-block pb-3 px-4 w-full text-slate-400">
                                <button
                                    id="popover-button"
                                    type="button"
                                    onClick={togglePopover}
                                    className="hs-tooltip-toggle w-full text-start flex items-center justify-between gap-x-3 py-2 px-3 text-sm text-slate-400 rounded-lg hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-neutral-700 focus:outline-none focus:bg-cyan-100 dark:text-neutral-300 dark:focus:bg-neutral-700">
                                    <div className="flex flex-col">
                                        <span>{user?.name}</span>
                                        <div className="font-light text-xs">
                                            {user?.role?.nameAlias}
                                        </div>
                                    </div>
                                    <DotsMenuIcon/>
                                </button>
                                {isPopoverOpen && (
                                    <div
                                        ref={popoverRef}
                                        className="absolute left-4 bottom-full mb-2 w-44 bg-white dark:bg-neutral-900 shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] rounded-lg z-50">
                                        <div className="p-1">

                                            <button
                                                key={"Profile"}
                                                type="button"
                                                onClick={handleMyProfile}
                                                className="w-full flex  py-1.5 px-3 rounded-lg text-[13px] text-gray-600 hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                My profile
                                            </button>
                                            <div className="border-t border-slate-100 mx-2 dark:border-neutral-700"/>

                                            <button
                                                key={"logout"}
                                                type="button"
                                                onClick={handleLogout}
                                                className="w-full flex  py-1.5 px-3 rounded-lg text-[13px] text-gray-600 hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-cyan-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800">
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </aside>
    );
};

export default SidebarContent;
