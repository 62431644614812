import {createAsyncThunk} from "@reduxjs/toolkit";
import {Program} from "types/program";
import {ProgramService} from "services/programService";
import {SearchType} from "types/search";
import {PayerService} from "../../services/payerService";

export class ProgramThunks {
    static index = createAsyncThunk(
        'program/index',
        async ({page, search, sortField, sortDirection, rowsPerPage}: {
            page: number,
            search: SearchType<{ [key: string]: any }>,
            sortField: string,
            sortDirection: string,
            rowsPerPage: number
        }) => {
            return await ProgramService.index(page, search, sortField, sortDirection, rowsPerPage);
        }
    );
    static reactivate = createAsyncThunk(
        'program/activate',
        async (id: string) => {
            return await ProgramService.reactivate(id);
        }
    );
    static deactivate = createAsyncThunk(
        'payer/deactivate',
        async (id: string) => {
            return await ProgramService.deactivate(id);
        }
    );

    static create = createAsyncThunk(
        'program/create',
        async (record: Program) => {
            return await ProgramService.create(record);
        }
    );

    static show = createAsyncThunk(
        'program/show',
        async (id: string) => {
            return await ProgramService.show(id);
        }
    );

    static update = createAsyncThunk(
        'program/update',
        async (record: Program) => {
            return await ProgramService.update(record);
        }
    );

    static delete = createAsyncThunk(
        'program/delete',
        async (id: string) => {
            return await ProgramService.delete(id);
        }
    );
}
