import {createSlice} from '@reduxjs/toolkit';
import { UserThunks} from 'features/user/userThunk';
import {showToast} from "components/ToastContainer";
import {User} from "types/user";

interface UserState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    users?: any;
    pagy?: any;
    user?: User | null;
    isEditing: boolean;
    activeTab?: string;
}

const initialState: UserState = {
    status: 'idle',
    error: null,
    users: [],
    pagy: null,
    user: null,
    isEditing: false,
    activeTab: 'All'
};

const handleUserStatusChange = (state: UserState, action: any, message: string) => {
    state.status = 'succeeded';
    showToast(message, "success");
    const updatedUser = action.payload;
    state.user = updatedUser;
    const index = state.users.findIndex((user: User) => user.id === updatedUser.id);
    if (index !== -1) {
        state.users[index] = updatedUser;
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserThunks.deactivate.fulfilled, (state, action) => {
                handleUserStatusChange(state, action, 'User deactivated successfully');
            })
            .addCase(UserThunks.reactivate.fulfilled, (state, action) => {
                handleUserStatusChange(state, action, 'User reactivated successfully');
            })
            .addCase(UserThunks.unlock.fulfilled, (state, action) => {
                handleUserStatusChange(state, action, 'User unlocked successfully');
            })
            .addCase(UserThunks.unblock.fulfilled, (state, action) => {
                handleUserStatusChange(state, action, 'User unblocked successfully');
            })
            .addCase(UserThunks.update.fulfilled, (state, action) => {
                handleUserStatusChange(state, action, 'User updated successfully');
            })
            .addCase(UserThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload;
            })
            .addCase(UserThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.users = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserThunks.create.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(UserThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })

    }
});

export const {setIsEditing, setActiveTab, setUser} = userSlice.actions;
export default userSlice.reducer;
