import {createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from "store/store";
import {AuthService} from "services/authService";



export class AuthThunks {
    static resetPassword = createAsyncThunk(
        'auth/resetPassword',
        async ({password, token}: { password: string, token: string }) => {
            return await AuthService.resetPassword(token, password);
        }
    );
    static verifyCode = createAsyncThunk(
        'auth/verifyCode',
        async (code: string, {getState}) => {
            const state = getState() as RootState;
            const {userCredentials} = state.auth;
            if (!userCredentials) {
                throw new Error("User credentials are missing");
            }
            return await AuthService.verifyCode(code, userCredentials);
        }
    );
    static login = createAsyncThunk(
        'auth/login',
        async (credentials: { email: string; password: string }) => {
            const response = await AuthService.login(credentials.email, credentials.password);
            return {...response, userCredentials: credentials};
        }
    );
    static forgotPassword = createAsyncThunk(
        'auth/forgotPassword',
        async (credentials: { email: string; }) => {
            return await AuthService.forgotPassword(credentials.email);
        }
    );
    static unlockScreen = createAsyncThunk(
        'auth/unLockScreen',
        async (pinCode: string) => {
            return await AuthService.unlockScreen(pinCode);
        }
    );
    static forgotPinCode = createAsyncThunk(
        'auth/forgotPinCode',
        async () => {
            return await AuthService.forgotPinCode();
        }
    );
    static lockScreen = createAsyncThunk(
        'auth/lockScreen',
        async () => {
            return await AuthService.lockScreen();
        }
    );
}

