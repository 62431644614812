export const validateEmail = (email: string) => {
    if (email.length === 0) {
        return true;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const validatePassword = (password: string) => {
    const minLength = password.length >= 1;

    return minLength && true;

};
