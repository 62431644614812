import React from "react";
import EyeIcon from "assets/images/icons/EyeIcon";

interface PasswordInputProps {
  label: string;
  password: string;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  passwordError: boolean;
  errorMessage?: string;
  showPassword: boolean;
  onToggleShowPassword: () => void;
  inputId: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  onPasswordChange,
  passwordError,
  showPassword,
  onToggleShowPassword,
  errorMessage,
  inputId,
  label,
  onFocus,
  onBlur,
}) => {
  return (
    <div>
      <div className="flex flex-col items-start gap-[0.625rem]">
        <label
          htmlFor={inputId}
          className="w-80 block mb-2 text-sm font-semibold tracking-tight text-slate-800 dark:text-white">
          {label}
        </label>
      </div>
      <div className="relative">
        <button
          type="button"
          data-testid="show-password"
          onClick={onToggleShowPassword}
          className="absolute top-3 end-0 flex items-center z-10 px-3 cursor-pointer text-gray-400 rounded-e-md
                                focus:outline-none focus:text-customBlue-300 dark:text-neutral-600 dark:focus:text-blue-500"
          tabIndex={3}>
          <EyeIcon isVisible={showPassword} />
        </button>
        <input
          id={inputId}
          type={showPassword ? "text" : "password"}
          data-testid={`${inputId}-field`}
          value={password}
          onChange={onPasswordChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={`pr-9 py-2.5 px-3 block w-full text-gray-500 ${passwordError ? "focus:outline-none" : "focus:outline-cyan-400 focus-visible:ring-cyan-400 focus-visible:border-cyan-400"} border  shadow rounded-lg text-sm text-gray-500 placeholder:text-gray-400
                                 disabled:bg-slate-100  disabled:text-slate-300 disabled:opacity-50 disabled:pointer-events-none 
                                dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 
                                dark:focus:ring-neutral-600 ${passwordError ? "border-red-500" : "border-slate-200"}`}
          required
          tabIndex={2}
        />
        {errorMessage && (
          <p className="text-xs mt-2 text-red-500 " id="password-error">
            {errorMessage}
          </p>
        )}
      </div>
    </div>
  );
};

export default PasswordInput;
