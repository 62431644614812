export interface CredentialInterface {
    id: string;
    name: string;
    description: string;
    expires: boolean;
    remindHr: boolean;
    hrReminderDays: number;
    remindUser: boolean;
    userReminderDays: number;
    requiresTraining: boolean;
    trainingLinks: string;
}

export class Credential implements CredentialInterface {
    id: string;
    name: string;
    description: string;
    expires: boolean;
    remindHr: boolean;
    hrReminderDays: number;
    remindUser: boolean;
    userReminderDays: number;
    requiresTraining: boolean;
    trainingLinks: string;

    constructor(data: Partial<CredentialInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.description = data.description!;
        this.expires = data.expires!;
        this.remindHr = data.remindHr!;
        this.hrReminderDays = data.hrReminderDays!;
        this.remindUser = data.remindUser!;
        this.userReminderDays = data.userReminderDays!;
        this.requiresTraining = data.requiresTraining!;
        this.trainingLinks = data.trainingLinks!;
    }

    toJson(): CredentialInterface {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            expires: this.expires,
            remindHr: this.remindHr,
            hrReminderDays: this.hrReminderDays,
            remindUser: this.remindUser,
            userReminderDays: this.userReminderDays,
            requiresTraining: this.requiresTraining,
            trainingLinks:this.trainingLinks,

        };
    }
}
