import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {Program} from "types/program";
import SubmitButton from "components/SubmitButton";
import {ProgramThunks} from "features/program/programThunks";
import {setIsEditing} from "features/program/programSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import CustomSelect from "components/inputs/CustomSelect";
import {Location} from "types/location";
import RadioInput from "components/inputs/RadioInput";
import {Credential} from "types/credential";

interface ProgramFormProps {
    id?: string;
    closeDrawer: () => void;
}

const ProgramForm: React.FC<ProgramFormProps> = ({id, closeDrawer}) => {
    const program = useSelector((state: RootState) => state.program.program);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedProgram, setUpdatedProgram] = useState({...program});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const location = useSelector((state: RootState) => state.location.locations);
    const locationOptions = location.map((location: Location) => ({
        value: location.id,
        label: location.name
    }));

    useEffect(() => {
        const isFormValid =
            updatedProgram.name &&
            updatedProgram.nameAlias &&
            updatedProgram.description &&
            updatedProgram.locationIds.length > 0 &&
            updatedProgram.showUnapproved !== undefined &&
            updatedProgram.allowAlternateReports !== undefined &&
            updatedProgram.noteMinuteIncrement !== undefined &&
            updatedProgram.blockOverlappingNotes !== undefined &&
            updatedProgram.includeObjectivesOnNotes !== undefined &&
            updatedProgram.requireCurrentAuthorization !== undefined &&
            updatedProgram.enableEventReminders !== undefined;

        setIsSaveDisabled(!isFormValid);
    }, [
        updatedProgram.name,
        updatedProgram.nameAlias,
        updatedProgram.description,
        updatedProgram.locationIds,
        updatedProgram.showUnapproved,
        updatedProgram.allowAlternateReports,
        updatedProgram.noteMinuteIncrement,
        updatedProgram.blockOverlappingNotes,
        updatedProgram.includeObjectives,
        updatedProgram.requireCurrentAuthorization,
        updatedProgram.enableEventReminders,
    ]);


    useEffect(() => {
        setUpdatedProgram({...program})
        setIsSaveDisabled(!program?.nameAlias);
    }, [program]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
            | { target: { id: string; value: any } }
    ) => {
        const { id, value } = e.target;
        const newValue = typeof value === "string" ? value.trim() : value;
        setUpdatedProgram({ ...updatedProgram, [id]: newValue });
        setIsSaveDisabled(typeof newValue === "string" && newValue === "");
    };
    const handleRadioChange = (field: string, value: boolean) => {
        setUpdatedProgram((prevState: Credential) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (program?.id) {
            await dispatch(ProgramThunks.update(new Program(updatedProgram))).then(() => closeDrawer());
        } else {
            await dispatch(ProgramThunks.create(new Program(updatedProgram))).then(() => closeDrawer());
        }
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        closeDrawer();
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div
                    className="h-[158px] px-6 pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                    <div className=" self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                            {program?.id ? 'Edit program' : 'Create new program'}
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {program?.id ? 'Edit ' : 'Create a new '}program and set program settings
                        </div>
                    </div>
                </div>
                <div className="flex-1 px-3 pb-6 pt-3 overflow-y-auto">
                    <div className="h-full flex flex-col overflow-y-auto overflow-hidden">
                        <div className="h-full px-4">
                            <form onSubmit={handleSubmit}>
                                <div className="flex flex-col space-y-6 items-center mt-4">
                                    <div className="w-full">
                                        <TextInput
                                            id="name"
                                            type="text"
                                            label="Name"
                                            value={updatedProgram?.name}
                                            placeholder="Name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInput
                                            id="programAbbreviation"
                                            type="text"
                                            label="Program Abbreviation"
                                            value={updatedProgram?.nameAlias}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <DescriptionInput
                                            id="description"
                                            type="text"
                                            label="Description"
                                            value={updatedProgram?.description}
                                            placeholder="Description"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <div className="flex flex-col gap-[0.625rem] w-full">
                                            <label htmlFor="roleId"
                                                   className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                                Applicable Location(s)
                                            </label>
                                            <CustomSelect
                                                options={locationOptions}
                                                isMulti={true}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions.map((option: any) => option.value);
                                                    setUpdatedProgram((prevData:Program) => ({
                                                        ...prevData,
                                                        locationIds: selectedValues
                                                    }));
                                                }}
                                                value={updatedProgram.locationIds}
                                            />
                                        </div>
                                    </div>
                                    <RadioInput
                                        label="Show unapproved documents to all assigned users"
                                        options={[
                                            {id: 'showUnapprovedYes', label: 'Yes', value: true},
                                            {id: 'showUnapprovedNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedProgram.showUnapproved}
                                        onChange={(value) => handleRadioChange('showUnapproved', value)}
                                    />
                                    <RadioInput
                                        label="Allow selection of alternate versions of reports"
                                        options={[
                                            {id: 'allowAlternateReportsYes', label: 'Yes', value: true},
                                            {id: 'allowAlternateReportsNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedProgram.allowAlternateReports}
                                        onChange={(value) => handleRadioChange('allowAlternateReports', value)}
                                    />
                                    <div  className="w-full">
                                            <div className="flex flex-col gap-[0.625rem] w-full ">
                                                <label htmlFor="roleId"
                                                       className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                                    Note minute increment
                                                </label>
                                                <CustomSelect
                                                    options={[
                                                        {value: 1, label: '1 minute'},
                                                        {value: 5, label: '5 minutes'},
                                                        {value: 15, label: '15 minutes'},
                                                    ]}
                                                    value={updatedProgram.noteMinuteIncrement}
                                                    onChange={(selectedOption) => handleChange({ target: { id: 'noteMinuteIncrement', value: selectedOption.value } })}
                                                />
                                            </div>
                                    </div>
                                    <RadioInput
                                        label="Block overlapping notes"
                                        options={[
                                            {id: 'blockOverlappingNotesYes', label: 'Yes', value: true},
                                            {id: 'blockOverlappingNotesNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedProgram.blockOverlappingNotes}
                                        onChange={(value) => handleRadioChange('blockOverlappingNotes', value)}
                                    />
                                    <RadioInput
                                        label="Include objectives on notes"
                                        options={[
                                            {id: 'includeObjectivesYes', label: 'Yes', value: true},
                                            {id: 'includeObjectives', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedProgram.includeObjectives}
                                        onChange={(value) => handleRadioChange('includeObjectives', value)}
                                    />
                                    <RadioInput
                                        label="Require current authorizations"
                                        options={[
                                            {id: 'requireCurrentAuthorizationYes', label: 'Yes', value: true},
                                            {id: 'requireCurrentAuthorizationNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedProgram.requireCurrentAuthorization}
                                        onChange={(value) => handleRadioChange('requireCurrentAuthorization', value)}
                                    />
                                    <RadioInput
                                        label="Enable event reminders"
                                        options={[
                                            {id: 'enableEventRemindersYes', label: 'Yes', value: true},
                                            {id: 'enableEventRemindersNo', label: 'No', value: false}
                                        ]}
                                        selectedValue={updatedProgram.enableEventReminders}
                                        onChange={(value) => handleRadioChange('enableEventReminders', value)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-5 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-fulljustify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(program.id ? 'Save' : 'Create new') + ' program'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>

    );
};

export default ProgramForm;
