import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {ProgramThunks} from "./programThunks";

export interface ProgramState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    programs?: any;
    refresh: number;
    pagy?: any;
    program?: any;
    isEditing?: boolean;
}

const initialState: ProgramState = {
    status: 'idle',
    error: null,
    programs: [],
    refresh: 0,
    program: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false
};

const ProgramSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        setProgram: (state, action) => {
            state.program = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(ProgramThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ProgramThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.program = action.payload;
                state.refresh += 1;
                state.programs = [...state.programs, action.payload];
                showToast('Program created successfully', "success");
            })
            .addCase(ProgramThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(ProgramThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ProgramThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedProgram = action.payload;
                state.program = updatedProgram;
                
                const index = state.programs.findIndex((item: any) => item.id === updatedProgram.id);
                if (index !== -1) {
                    state.programs[index] = updatedProgram;
                }
                showToast('Program updated successfully', "success");
            })
            .addCase(ProgramThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(ProgramThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ProgramThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.program = action.payload;
            })
            .addCase(ProgramThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(ProgramThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ProgramThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.programs = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(ProgramThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(ProgramThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ProgramThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.refresh += 1;
                state.programs = state.programs.filter((item: any) => item.id !== action.payload);
                showToast('Program deleted successfully', "success");
            })
            .addCase(ProgramThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setProgram, setIsEditing} = ProgramSlice.actions;
export {initialState as programInitialState};
export default ProgramSlice.reducer;
