import { createSlice } from '@reduxjs/toolkit';
import { showToast } from "components/ToastContainer";
import {UserInvitationThunks} from 'features/userInvitation/userInvitationThunk';

export interface UserInvitationState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    refresh: number;
    invitation?: any;
    userInvitations?: any;
    pagy?: any;
    userInvitation?: any;
}

const initialState: UserInvitationState = {
    status: 'idle',
    error: null,
    invitation: null,
    userInvitations: [],
    pagy: null,
    refresh: 0,
    userInvitation: null
};

const userInvitationSlice = createSlice({
    name: 'userInvitation',
    initialState,
    reducers: {
        setInvitation: (state, action) => {
            state.invitation = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserInvitationThunks.index.pending, (state) => {
                state.userInvitation = null;
                state.status = 'loading';
            })
            .addCase(UserInvitationThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userInvitation = action.payload;
            })
            .addCase(UserInvitationThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userInvitations = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(UserInvitationThunks.showByToken.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserInvitationThunks.showByToken.fulfilled, (state, action) => {
                state.invitation = action.payload;
                state.status = 'succeeded';
            })
            .addCase(UserInvitationThunks.showByToken.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(UserInvitationThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(UserInvitationThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.refresh += 1;
                showToast('Staff Invitation sent', 'success');
            })
            .addCase(UserInvitationThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(UserInvitationThunks.cancel.fulfilled, (state) => {
                state.status = 'succeeded';
                showToast('Staff Invitation cancelled', 'success');
            })




    }
});

export const { setInvitation } = userInvitationSlice.actions;

export { initialState as userInvitationInitialState };
export default userInvitationSlice.reducer;
