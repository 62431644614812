import {formatDate} from '../utils/dateConverter';

export interface UserCredentialInterface {
    id: string;
    name: string;
    licensingAuthority: string;
    status: string;
    expirationDate: string;
    remindUserAt: string;
    credentialId?: string;
    userId?: string;
    notes?: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;
}

export class UserCredential implements UserCredentialInterface {
    id: string;
    name: string;
    licensingAuthority: string;
    status: string;
    expirationDate: string;
    remindUserAt: string;
    credentialId?: string;
    userId?: string;
    notes?: string;
    attachment?: any;
    attachmentUrl?: string;
    attachmentSize?: number;

    constructor(data: Partial<UserCredentialInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.licensingAuthority = data.licensingAuthority!;
        this.status = data.status!;
        this.expirationDate = data.expirationDate!;
        this.remindUserAt = data.remindUserAt!;
        this.credentialId = data.credentialId;
        this.userId = data.userId;
        this.notes = data.notes;
        this.attachment = data.attachment;
        this.attachmentUrl = data.attachmentUrl;
        this.attachmentSize = data.attachmentSize;
    }

    toJson(): UserCredentialInterface {
        return {
            id: this.id,
            name: this.name,
            licensingAuthority: this.licensingAuthority,
            status: this.status,
            expirationDate: formatDate(this.expirationDate),
            remindUserAt: formatDate(this.remindUserAt),
            credentialId: this.credentialId,
            userId: this.userId,
            notes: this.notes,
            attachment: this.attachment
        };
    }
}
