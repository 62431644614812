import React, {useEffect, useRef, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Role} from "types/role";
import Table from "components/table/Table";
import {UserThunks} from "features/user/userThunk";
import {User} from "types/user";
import {SearchType} from "types/search";
import {setActiveTab} from "features/user/userSlice";
import SSNDisplay from "components/SSNDisplay";
import PhoneNumberDisplay from "components/PhoneNumberDisplay";
import CheckCircle from "assets/images/icons/CheckCircle";
import XCircle from "assets/images/icons/XCircle";
import Blocked from "assets/images/icons/Blocked";
import Locked from "assets/images/icons/Locked";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";
import {useNavigate} from "react-router-dom";
import Alert from "assets/images/icons/Alert";
import DotsMenu from "components/DotsMenu";
import {Circle} from "@react-google-maps/api";
import CircleDashedIcon from "../../assets/images/icons/CircleDashedIcon";

const HrTable: React.FC<{ openNewDrawer: () => void }> = ({openNewDrawer}) => {
    const users = useSelector((state: RootState) => state.user.users);
    const selectRoles = useSelector((state: RootState) => state.role.selectableRoles);
    const credential = useSelector((state: RootState) => state.credential.credential);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.user.pagy);
    const activeTab = useSelector((state: RootState) => state.user.activeTab);
    const navigate = useNavigate();
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    interface VisibleColumnsType {
        name: boolean;
        workEmail: boolean;
        personalEmail: boolean;
        ssn: boolean;
        phoneNumber: boolean;
        roles: boolean;
        status: boolean;
        location: boolean;
        credentials: boolean;
        lastLogin: boolean;
        supervisors: boolean;
        expDate: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
        name: true,
        workEmail: true,
        personalEmail: true,
        ssn: true,
        phoneNumber: true,
        roles: true,
        status: true,
        location: true,
        credentials: true,
        lastLogin: true,
        supervisors: true,
        expDate: true,
    });

    const columnOptions = [
        {label: 'Name', value: 'name', isVisible: visibleColumns.name},
        {label: 'Work Email', value: 'workEmail', isVisible: visibleColumns.workEmail},
        {label: 'Personal Email', value: 'personalEmail', isVisible: visibleColumns.personalEmail},
        {label: 'SSN', value: 'ssn', isVisible: visibleColumns.ssn},
        {label: 'Phone Number', value: 'phoneNumber', isVisible: visibleColumns.phoneNumber},
        {label: 'Roles', value: 'roles', isVisible: visibleColumns.roles},
        {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        {label: 'Location', value: 'location', isVisible: visibleColumns.location},
        {label: 'Credentials', value: 'credentials', isVisible: visibleColumns.credentials},
        {label: 'Last Login', value: 'lastLogin', isVisible: visibleColumns.lastLogin},
        {label: 'Supervisors', value: 'supervisors', isVisible: visibleColumns.supervisors},
        {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expDate},
    ];
    const searchByOptions = [
        {label: 'Employee ID', value: 'id', operator: '=='},
        {label: 'Name', value: 'name', operator: 'like'},
        {label: 'Email', value: 'email', operator: 'like'},
        {label: 'SSN', value: 'ssn', operator: 'like'},
    ];

    const fetchUserData = async ({page, search, sortField, sortDirection, rowsPerPage,}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive' || activeTab === 'Onboarding') {
            search.where.status = {
                operator: '==',
                value: activeTab.toLowerCase()
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }

        await dispatch(UserThunks.index({page, search, sortField, sortDirection, rowsPerPage,}));
    };


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column as keyof VisibleColumnsType]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id?: string) => async () => {
        navigate(`/hr/users/${id}`);
    }
    const handleDeactivate = (userId: string) => {
        dispatch(UserThunks.deactivate(userId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (userId: string) => {
        dispatch(UserThunks.reactivate(userId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleUnlock = (userId: string) => {
        dispatch(UserThunks.unlock(userId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleUnblock = (userId: string) => {
        dispatch(UserThunks.unblock(userId) as any);
        setDotsMenuIsOpen(null);
    };
    const handleEdit = (userId: string) => {
    };


    return (
        <Table
            fetchData={fetchUserData}
            pagy={pagy}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Onboarding', 'Active', 'Inactive']}
            recordsName={'Employees'}
            searchByOptions={searchByOptions}
            blueButton={<BlueButton onClick={openNewDrawer} label={''} icon={<Plus/>}/>}
            columns={true}
            columnOptions={columnOptions}
            onColumnToggle={handleColumnToggle}
        >
            <thead>
            <tr>
                <th scope="col" className="px-3  pt-3.5  flex justify-center">
                    <input type="checkbox"
                           className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"/>
                </th>
                {visibleColumns.name && <TableHeader label="Name" sortBy="name"/>}
                {visibleColumns.status && <TableHeader label="Status" sortBy="status"/>}
                {visibleColumns.credentials && <TableHeader label="Credentials" sortBy="Credentials"/>}
                {visibleColumns.expDate && <TableHeader label="Exp date" sortBy="ExpDate"/>}
                {visibleColumns.workEmail && <TableHeader label="Work Email" sortBy="email"/>}
                {visibleColumns.personalEmail && <TableHeader label="Personal Email" sortBy="personalEmail"/>}
                {visibleColumns.ssn && <TableHeader label="SSN" sortBy="ssn"/>}
                {visibleColumns.phoneNumber && <TableHeader label="Phone Number" sortBy="phoneNumber"/>}
                {visibleColumns.roles && <TableHeader label="Roles" sortBy="role"/>}
                {visibleColumns.location && <TableHeader label="Location" sortBy="location"/>}
                {visibleColumns.supervisors && selectRoles.length > 0 && (
                    selectRoles.map((role: Role) => (
                        <TableHeader key={role.id} label={role.nameAlias || role.name}
                                     sortBy={`supervisor_${role.name}`}/>
                    ))
                )}
                {visibleColumns.lastLogin && <TableHeader label="Last Login" sortBy="lastLogin"/>}
                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {users.length > 0 && (
                users.map((user: User) => {
                    const userOptions = [];
                    userOptions.push({
                        label: "Edit",
                        onClick: () => handleEdit(user.id),
                        hoverClass: "hover:bg-cyan-100",
                    });
                    if (user.status === "Active") {
                        userOptions.push({
                            label: "Deactivate",
                            icon: <Alert/>,
                            onClick: () => handleDeactivate(user.id),
                            hoverClass: "hover:bg-red-100 hover:text-red-800",
                        });
                    }
                    if (user.status === "Inactive") {
                        userOptions.push({
                            label: "Reactivate",
                            onClick: () => handleReactivate(user.id),
                            hoverClass: "hover:bg-cyan-100",
                        });
                    }
                    if (user.locked) {
                        userOptions.push({
                            label: "Unlock",
                            onClick: () => handleUnlock(user.id),
                            hoverClass: "hover:bg-cyan-100",
                        });
                    }
                    if (user.blocked) {
                        userOptions.push({
                            label: "Unblock",
                            onClick: () => handleUnblock(user.id),
                            hoverClass: "hover:bg-cyan-100",
                        });
                    }


                    return (
                    <TableRow
                        key={user.id}
                        onClick={handleOpenDrawer(user.id)}
                        className="cursor-pointer hover:bg-cyan-50 focus:bg-cyan-100 dark:hover:bg-neutral-700 dark:text-neutral-300"
                    >
                        <td scope="col" className="pt-3.5 flex justify-center">
                            <input
                                type="checkbox"
                                className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                onClick={(e) => e.stopPropagation()}/>
                        </td>

                        {visibleColumns.name && <TableCell>
                            <div className=" relative group">
                                <div className="w-full flex items-center gap-x-3">
                                    <div className="flex-grow">
                                        <span className="text-sm font-medium dark:text-neutral-200 underline text-cyan-600">
                                            {user.firstName} {user.lastName}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </TableCell>}
                        {visibleColumns.status && (
                            <TableCell>
                             <span
                                 className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-[123px]
                            ${user.status === 'Active' && !user.locked && !user.blocked ? 'bg-green-100 text-green-800' : ''}
                            ${user.status === 'Inactive' && !user.locked && !user.blocked ? 'bg-red-100 text-red-800' : ''}
                            ${user.status === 'Onboarding' && !user.locked && !user.blocked ? 'bg-yellow-100 text-yellow-800' : ''}
                            ${user.locked ? 'bg-gray-100 text-gray-700' : ''}
                            ${user.blocked ? 'bg-gray-100 text-gray-700' : ''}
                        `}
                             >
                        {user.status === 'Active' && !user.locked && !user.blocked && <CheckCircle className="mr-2"/>}
                                 {user.status === 'Inactive' && !user.locked && !user.blocked &&
                                     <XCircle className="mr-2"/>}
                                 {user.status === 'Onboarding' && !user.locked && !user.blocked &&
                                     <CircleDashedIcon className="mr-2"/>}
                                 {user.blocked && <Blocked className="mr-2"/>}
                                 {user.locked && <Locked className="mr-2"/>}
                                 <div className="pl-1">
                            {user.status === 'Active' && !user.locked && !user.blocked && user.status}
                                     {user.status === 'Inactive' && !user.locked && !user.blocked && 'Inactive'}
                                     {user.status === 'Onboarding' && !user.locked && !user.blocked && 'Onboarding'}
                                     {user.blocked && 'Blocked'}
                                     {user.locked && 'Locked'}
                        </div>
                     </span>
                            </TableCell>)}
                        {visibleColumns.credentials && <TableCell>{user.credentials}</TableCell>}
                        {visibleColumns.expDate && <TableCell>{credential.expDate}</TableCell>}
                        {visibleColumns.workEmail && <TableCell>{user.email}</TableCell>}
                        {visibleColumns.personalEmail && <TableCell>{user.personalEmail}</TableCell>}
                        {visibleColumns.ssn && <TableCell><SSNDisplay value={user.ssn || ''}/></TableCell>}
                        {visibleColumns.phoneNumber &&
                            <TableCell><PhoneNumberDisplay value={user.phoneNumber || ''}/></TableCell>}
                        {visibleColumns.roles && <TableCell>{user.role?.nameAlias}</TableCell>}

                        {visibleColumns.location && <TableCell>{user.location}</TableCell>}
                        {visibleColumns.supervisors && selectRoles.length > 0 && (
                            selectRoles.map((role: Role) => (
                                <TableCell key={role.id}>
                                    {user.supervisors?.find(s => s.role.id === role.id)?.user.name}
                                </TableCell>
                            ))
                        )}
                        {visibleColumns.lastLogin && <TableCell>{user.lastLogin}</TableCell>}
                        <TableCell>
                            <DotsMenu options={userOptions}/>
                        </TableCell>
                    </TableRow>
                    )
                    }
                ))}

            </tbody>
        </Table>
    );
};

export default HrTable;
