import apiClient from './apiClient';

export class AuthService {
    static async login(email: string, password: string) {
        const response = await apiClient.post('api/v1/login', { user: { email, password } });
        return response.data;
    }
    static async verifyCode(code: string, userCredentials: { email: string; password: string }) {
        const { email, password } = userCredentials;
        const response = await apiClient.post('api/v1/verify_otp', { otpAttempt: code, email, password });
        return response.data;
    }
    static async resetPassword(token: string, password: string) {
        const response = await apiClient.put('/api/v1/password', { user: { resetPasswordToken: token, password }});
        return response.data;
    }
    static async forgotPassword(email: string) {
        const response = await apiClient.post( '/api/v1/password', { user: { email } });
        return response.data;
    }
    static async unlockScreen(pinCode:string) {
        const response = await apiClient.patch('/api/v1/users/unlock_user_screen', { user: { pinCode } });
        return response.data;
    }
    static async forgotPinCode() {
        const response = await apiClient.post('/api/v1/users/pin_reminder');
        return response.data;
    }
    static async lockScreen() {
        const response = await apiClient.patch('/api/v1/users/lock_user_screen');
        return response.data;
    }


}

