import React from "react";

interface SecondStepVerificationProps {
    pin: string[];
    firstInputRef: React.RefObject<HTMLInputElement>;
    error: string | null;
    handlePinChange: (value: string, index: number) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number) => void;
    handlePaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

const SecondStepVerification: React.FC<SecondStepVerificationProps> = ({
                                                                           pin,
                                                                           firstInputRef,
                                                                           error,
                                                                           handlePinChange,
                                                                           handleKeyDown,
                                                                           handlePaste
                                                                       }) => {
    return (
        <div className="w-80 ">
            <div className="grid grid-cols-6 justify-items-start pr-6">
                {pin.map((data, index) => (
                    <input
                        data-hs-pin-input
                        key={index}
                        type="text"
                        id={`pin-input-${index}`}
                        maxLength={1}
                        ref={index === 0 ? firstInputRef : null}
                        className={`mt-12 py-2 px-1 block w-[40px] h-[46px] shadow border bg-white text-center rounded-lg text-sm
                            placeholder:text-gray-400 outline-cyan-400 disabled:opacity-50
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300
                            ${error ? "border-red-500" : "border-slate-200"}`}
                        value={data}
                        onChange={(e) => handlePinChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        onFocus={(e) => e.target.select()}
                    />
                ))}
            </div>

            {error && <p className="text-red-500 mt-2 text-xs">{error}</p>}
        </div>
    );
};

export default SecondStepVerification;
