import { useState, useEffect, useRef } from "react";

interface MenuPosition {
    top: number;
    left: number;
}

export const useDropdownActionMenu = (excludeIds: string[] = []) => {
    const [menuPosition, setMenuPosition] = useState<MenuPosition>({ top: 0, left: 0 });
    const [dotsMenuIsOpen, setDotsMenuIsOpen] = useState<string | null>(null);
    const dotsMenuRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
        if (dotsMenuIsOpen === id) {
            setDotsMenuIsOpen(null);
        } else {
            const button = (event.target as HTMLElement).closest("button");
            if (button) {
                const buttonRect = button.getBoundingClientRect();
                setMenuPosition({
                    top: buttonRect.top + buttonRect.height,
                    left: buttonRect.left - 90,
                });
                setDotsMenuIsOpen(id);
            }
        }
    };

    const closeDropdown = () => {
        setDotsMenuIsOpen(null);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;

            if (
                dotsMenuRef.current &&
                !dotsMenuRef.current.contains(target) &&
                !excludeIds.includes(target.id) &&
                !excludeIds.some((className) => target.classList.contains(className))
            ) {
                closeDropdown();
            }
        };

        const handleScroll = () => {
            closeDropdown();
        };

        document.addEventListener("mousedown", handleOutsideClick);
        window.addEventListener("scroll", handleScroll, true);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, [excludeIds]);

    return { dotsMenuIsOpen, menuPosition, toggleDropdown, closeDropdown, dotsMenuRef, setDotsMenuIsOpen };
};
