import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "store/store";
import {AuthThunks} from 'features/auth/authThunks';
import {UserThunks} from "features/user/userThunk";
import {User} from "types/user";

export interface AuthState {
    user: User | null
    userCredentials: { email: string; password: string; signature: string, credentials: any } | null;
    token: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    isLocked: boolean;

}

const initialState: AuthState = {
    user: null,
    userCredentials: null,
    token: null,
    status: 'idle',
    error: null,
    isLocked: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<any>) => {
            if (state.userCredentials) {
                state.userCredentials.credentials = action.payload;
            } else {
                state.userCredentials = {email: '', password: '', signature: '', credentials: action.payload};
            }
        },
        setSignature: (state, action) => {
            if (state.userCredentials) {
                state.userCredentials.signature = action.payload;
            } else {
                state.userCredentials = {email: '', password: '', credentials: {}, signature: action.payload};
            }
        },

        setPassword: (state, action) => {
            if (state.userCredentials) {
                state.userCredentials.password = action.payload;
            } else {
                state.userCredentials = {email: '', password: action.payload, signature: '', credentials: {}};
            }
        },
        logout(state) {
            state.user = null;
            state.userCredentials = null;
            state.token = null;
            state.isLocked = false;
            localStorage.removeItem('token');
        },
        setAuthUser(state, action) {
            state.user = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCredentials = action.payload.userCredentials;
            })
            .addCase(AuthThunks.login.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(AuthThunks.login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.userCredentials = action.payload.userCredentials;
            })
            .addCase(AuthThunks.login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(AuthThunks.verifyCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(AuthThunks.verifyCode.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.token;
                state.user = action.payload.user;
                localStorage.setItem('token', action.payload.token);
                state.userCredentials = null;
            })
            .addCase(AuthThunks.verifyCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(AuthThunks.unlockScreen.fulfilled, (state) => {
                state.isLocked = false;
            })
            .addCase(AuthThunks.lockScreen.fulfilled, (state) => {
                state.isLocked = true;
            })

    },
});


export const {logout, setPassword, setAuthUser, setSignature, setCredentials} = authSlice.actions;

export const selectUserCredentials = (state: RootState) => state.auth.userCredentials;

export {initialState as authInitialState};

export default authSlice.reducer;


