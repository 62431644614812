import React, {useEffect} from 'react';
import PencilSquare from "assets/images/icons/PencilSquare";
import SubmitButton from "components/SubmitButton";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import {Credential} from "types/credential";
import LoadingSpinner from "components/LoadingSpinner";
import {setIsEditing} from "features/credential/credentialSlice";

interface CredentialDetailsProps {
    onClose: () => void;
}

const CredentialDetails: React.FC<CredentialDetailsProps> = ({onClose}) => {
    const dispatch = useDispatch<AppDispatch>();
    const credential = useSelector((state: RootState) => state.credential.credential as Credential);

    const handleEditing = () => () => {
        dispatch(setIsEditing(true));
    }

    useEffect(() => {
        dispatch(setIsEditing(false));
    });

    return (
        <div className="flex flex-col h-screen">
            {credential !== null &&
                <div className="flex-1 flex flex-col">
                    <div className="flex-1 overflow-y-auto">
                        <div
                            className="h-full flex flex-col overflow-y-auto overflow-hidden [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
                            <div
                                className="h-[158px] pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                                <div className=" pl-6 self-stretch flex-col justify-start items-start gap-1 flex">
                                    <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                                        {credential?.name}
                                    </div>
                                </div>
                            </div>
                            <div className="py-2 px-4 sm:px-6">
                                <div className="h-full">
                                    <dl className="py-4 grid grid-cols-3 gap-x-4  dark:border-neutral-700">
                                        <dt className="col-span-1">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Name:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2  flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {credential?.name}
                                            </p>
                                        </dd>
                                    </dl>

                                    <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                                        <dt className="col-span-1">
                                            <p className="text-sm text-gray-500 dark:text-neutral-500">
                                                Description:
                                            </p>
                                        </dt>
                                        <dd className="col-span-2  flex items-center">
                                            <p className="text-sm text-gray-800 dark:text-neutral-200">
                                                {credential?.description}
                                            </p>
                                        </dd>
                                    </dl>

                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="flex-shrink-0 px-7 pb-4 flex justify-end gap-5">
                        <div className="border-t border-slate-200 w-full">
                            <div
                                className="flex justify-center items-center gap-x-2 pb-4">
                                <SubmitButton onClick={handleEditing()} label={<><PencilSquare/> {'Edit credential'} </>}
                                              disabled={false}/>
                            </div>
                            <div
                                className="flex  justify-center items-center gap-x-2 pb-4">
                                <button type="submit" onClick={onClose}
                                        className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                                    Never mind
                                </button>
                            </div>
                        </div>
                    </footer>
                </div>
            }
            {credential === null &&
                <div className="h-full"><LoadingSpinner/></div>
            }
        </div>
    );
};

export default CredentialDetails;
