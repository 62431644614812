import React, {useEffect, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Role} from "types/role";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import OpenSidebar from "assets/images/icons/OpenSidebar";
import {RoleThunks} from "./roleThunks";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";

interface RoleTableProps {
    openDrawer: (id?: string) => void;
    handleNewRoleOpenDrawer: () => void;
}

const RoleTable: React.FC<RoleTableProps> = ({openDrawer, handleNewRoleOpenDrawer}) => {
    const roles = useSelector((state: RootState) => state.role.roles);
    const refresh = useSelector((state: RootState) => state.role.refresh);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.role.pagy);

    const [visibleColumns, setVisibleColumns] = useState({
        name: true,
        selectable: true,
        description: true
    });

    interface VisibleColumnsType {
        name: boolean,
        selectable: boolean,
        description: boolean
    }

    const fetchUserData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{}>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        await dispatch(RoleThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };
    const columnOptions = [
        { label: 'Name', value: 'name', isVisible: visibleColumns.name },
        { label: 'Selectable', value: 'selectable', isVisible: visibleColumns.selectable },
        { label: 'Description', value: 'description', isVisible: visibleColumns.description }
    ];

    const searchByOptions = [
        {label: 'Name', value: 'name', operator: 'like'},
    ];


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id?: string) => async () => {
        if (id) {
            await dispatch(RoleThunks.show(id));
        }
        openDrawer();
    }

    useEffect(() => {
        fetchUserData({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10});
    }, [refresh]);



    return (

        <Table fetchData={fetchUserData}
               pagy={pagy}
               searchable={true}
               pageable={true}
               recordsName={'Roles'}
               columns={true}
               columnOptions={columnOptions}
               onColumnToggle={handleColumnToggle}
               searchByOptions={searchByOptions}
               blueButton={<BlueButton onClick={handleNewRoleOpenDrawer}  label="" icon={<Plus/>}/>}>
            <thead>
            <tr>
                {visibleColumns.name &&<TableHeader label="Name"/>}
                {visibleColumns.selectable && <TableHeader label="Description"/>}
                {visibleColumns.selectable && <TableHeader label="Selectable"/>}
            </tr>
            </thead>

            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">

            {roles.length > 0 && (
                roles.map((role: Role) => (
                    <TableRow
                        key={role.id}
                        onClick={handleOpenDrawer(role.id)}
                        className="cursor-pointer hover:bg-cyan-50 focus:bg-cyan-100 dark:hover:bg-neutral-700 dark:text-neutral-300"
                    >
                        {visibleColumns.name &&<TableCell>
                            <div className="relative group py-0.5">
                                <div className="w-full flex items-center gap-x-3">
                                    <div className="flex-grow">
                                        <span className="text-sm font-medium text-gray-600 dark:text-neutral-200">

                                            {role.nameAlias}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </TableCell>}
                        {visibleColumns.description &&<TableCell>{role.description}</TableCell>}
                        {visibleColumns.selectable &&<TableCell>{role.selectable}</TableCell>}

                    </TableRow>
                ))
            )}
            </tbody>

        </Table>

    );
};

export default RoleTable;
