    import React, {useEffect, useState} from "react";
    import { LocationThunks } from "features/location/locationThunks";
    import {useDispatch, useSelector} from "react-redux";
    import {AppDispatch, RootState} from "store/store";
    import Drawer from "components/Drawer";
    import LocationTable from "features/location/LocationTable";
    import LocationForm from "features/location/LocationForm";
    import LocationDetails from "features/location/LocationDetails";


    const LocationsPage: React.FC = () => {
        const dispatch = useDispatch<AppDispatch>();
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);
        const isEditing = useSelector((state: RootState) => state.location.isEditing);


        useEffect(() => {
            const loadLocations = async () => {
                try {
                    await dispatch(LocationThunks.index({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10}));
                } catch (err) {
                    console.error(err);
                }
            }
            loadLocations();
        }, [dispatch]);

        return (
            <div>
                <div >
                    <div className="relative">
                        <LocationTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                            {isEditing ? <LocationForm closeDrawer={closeDrawer}  /> : <LocationDetails onClose={closeDrawer} />}
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default LocationsPage;
