import {Role} from "./role";

export interface UserInvitationInterface {
    id?: string;
    email: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: Role;
    ssn: string;
    supervisorUserIds?: string[];
    supervisors?: any[];
    status?: string;
    lastLogin?: string;
    credentialIds?: string[];
    roleId?: string;
}

export class UserInvitation implements UserInvitationInterface {
    id: string;
    email: string;
    personalEmail: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: Role;
    ssn: string;
    supervisorUserIds: string[];
    supervisors?: any[];
    status: string;
    lastLogin: string;
    credentialIds: string[];
    roleId: string;

    constructor(data: Partial<UserInvitationInterface> = {}) {
        this.id = data.id || '';
        this.email = data.email || '';
        this.personalEmail = data.personalEmail || '';
        this.firstName = data.firstName || '';
        this.lastName = data.lastName || '';
        this.phoneNumber = data.phoneNumber || '';
        this.role = new Role(data.role || {});
        this.supervisors = data.supervisors || [];
        this.ssn = data.ssn || '';
        this.supervisorUserIds = data.supervisorUserIds || [];
        this.status = data.status || 'Pending';
        this.lastLogin = data.lastLogin || '';
        this.credentialIds = data.credentialIds || [];
        this.roleId = data.roleId || '';
    }

    toJson() {
        return {
            id: this.id,
            email: this.email,
            personalEmail: this.personalEmail,
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            roleId: this.roleId,
            ssn: this.ssn,
            supervisorUserIds: this.supervisorUserIds,
            lastLogin: this.lastLogin,
            credentialIds: this.credentialIds,
        };
    }

    static fromJson(data: UserInvitationInterface): UserInvitation {
        return new UserInvitation(data);
    }
}
