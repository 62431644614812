import React from "react";

interface TextInputProps {
  id: string;
  type: string;
  label: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void | undefined;
  error?: string | undefined;
  placeholder?: string;
  tabIndex?: number;
  disabled?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  type,
  label,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  tabIndex,
  disabled,
}) => {
  return (
    <div className="flex flex-col items-start gap-[0.625rem]">
      <label
        htmlFor={id}
        className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
        {label}
      </label>

      <input
        type={type}
        id={id}
        data-testid={`${id}-field`}
        value={value}
        onChange={onChange}
        className={`w-full py-2.5 px-3 block focus:outline-cyan-400 border border-slate-200  text-gray-500 shadow rounded-lg text-sm font-light placeholder:text-gray-400 
                            disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none 
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60  
                            dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-gray-200"}`}
        placeholder={placeholder}
        required
        aria-describedby={`${id}-error`}
        tabIndex={tabIndex}
        disabled={disabled}
        onBlur={onBlur}
      />
      {error && (
        <p className="text-xs text-red-600 " id={`${id}-error`}>
          {error}
        </p>
      )}
    </div>
  );
};

export default TextInput;
