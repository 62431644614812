import React, {useEffect, useRef, useState} from "react";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import TableCell from "components/table/TableCell";
import {Program} from "types/program";
import Table from "components/table/Table";
import {SearchType} from "types/search";
import {ProgramThunks} from "./programThunks";
import {setIsEditing, setProgram} from "features/program/programSlice";
import BlueButton from "components/BlueButton";
import Plus from "assets/images/icons/Plus";
import {setActiveTab} from "../user/userSlice";
import CheckCircle from "assets/images/icons/CheckCircle";
import XCircle from "assets/images/icons/XCircle";
import Alert from "assets/images/icons/Alert";
import DotsMenu from "components/DotsMenu";
import {useDropdownActionMenu} from "hooks/useDropdownActionMenu";

const ProgramTable: React.FC<{ openDrawer: () => void }> = ({openDrawer}) => {
    const programs = useSelector((state: RootState) => state.program.programs);
    const refresh = useSelector((state: RootState) => state.program.refresh);
    const dispatch = useDispatch<AppDispatch>();
    const pagy = useSelector((state: RootState) => state.program.pagy);
    const activeTab = useSelector((state: RootState) => state.user.activeTab);
    const {setDotsMenuIsOpen} = useDropdownActionMenu();

    interface VisibleColumnsType {
        id: boolean;
        name: boolean;
        description: boolean;
        status: boolean;
    }

    const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
        id: true,
        name: true,
        description: true,
        status: true,
    });

    const columnOptions = [
        { label: 'ID', value: 'id', isVisible: visibleColumns.id },
        { label: 'Name', value: 'name', isVisible: visibleColumns.name },
        { label: 'Description', value: 'description', isVisible: visibleColumns.description },
        { label: 'Status', value: 'status', isVisible: visibleColumns.status }
    ];
    const searchByOptions = [
        { label: 'ID', value: 'id', operator: '=='},
        { label: 'Name', value: 'name', operator: 'like' }
    ];

    const fetchData = async ({page, search, sortField, sortDirection, rowsPerPage}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'Active' || activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: activeTab === 'Active' ? 'Active' : 'Disabled'
            };
        } else {
            search.where.status = {
                operator: '==',
                value: ''
            }
        }
        await dispatch(ProgramThunks.index({page, search, sortField, sortDirection, rowsPerPage}));
    };


    const handleColumnToggle = (column: string) => {
        if (column in visibleColumns) {
            setVisibleColumns((prev) => ({
                ...prev,
                [column]: !prev[column as keyof VisibleColumnsType],
            }));
        }
    };

    const handleOpenDrawer = (id: string) => async () => {
        dispatch(setIsEditing(false));
        openDrawer();
        await dispatch(ProgramThunks.show(id));
    };

    const handleOpenNewProgramDrawer = () => {
        dispatch(setProgram({
            id: '',
            name: '',
            description: '',
            status: '',
        }));
        dispatch(setIsEditing(true));
        openDrawer();
    };
    const handleDeactivate = (programId: string) => {
        dispatch(ProgramThunks.deactivate(programId) as any);
        setDotsMenuIsOpen(null);
    };

    const handleReactivate = (programId: string) => {
        dispatch(ProgramThunks.reactivate(programId) as any);
        setDotsMenuIsOpen(null);
    };


    useEffect(() => {
        fetchData({page: 1, search: {where: {}}, sortField: '', sortDirection: '', rowsPerPage: 10});
    }, [refresh]);



    return (
        <Table
            fetchData={fetchData}
            pagy={pagy}
            pageable={true}
            activeTab={activeTab}
            setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
            tabs={['All', 'Active', 'Inactive']}
            onColumnToggle={handleColumnToggle}
            searchByOptions={searchByOptions}
            recordsName={'Programs'}
            columnOptions={columnOptions}
            searchable={true}
            columns={true}
            blueButton={<BlueButton onClick={handleOpenNewProgramDrawer} label="" icon={<Plus/>}/>}
        >
            <thead>
            <tr>
                {visibleColumns.id && <TableHeader label="ID"/>}
                {visibleColumns.name && <TableHeader label="Name"/>}
                {visibleColumns.description && <TableHeader label="Description"/>}
                {visibleColumns.status && <TableHeader label="Status"/>}
                <TableHeader label=""/>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
            {programs.map((program: Program) => {
                const userOptions = [];
                if (program.status === "Active") {
                    userOptions.push({
                        label: "Deactivate",
                        icon: <Alert />,
                        onClick: () => handleDeactivate(program.id),
                        hoverClass: "hover:bg-red-100 hover:text-red-800",
                    });
                }
                if (program.status === "Inactive") {
                    userOptions.push({
                        label: "Reactivate",
                        icon: <CheckCircle />,
                        onClick: () => handleReactivate(program.id),
                        hoverClass: "hover:bg-green-100 hover:text-green-800",
                    });
                }
                return (
                <TableRow
                    key={program.id}
                    onClick={handleOpenDrawer(program.id)}
                    className="cursor-pointer hover:bg-cyan-50 focus:bg-cyan-100 dark:hover:bg-neutral-700 dark:text-neutral-300"
                >
                    {visibleColumns.id && <TableCell>{program.id}</TableCell>}
                    {visibleColumns.name && <TableCell>{program.name}</TableCell>}
                    {visibleColumns.description && <TableCell>{program.description}</TableCell>}
                    {visibleColumns.status && (
                    <TableCell>
                            <span
                                className={`flex items-center text-xs font-semibold px-2 py-0.5 rounded-full w-[112px]
                                    ${program.status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-700'}`}
                            >
                                {program.status === 'Active' ? <CheckCircle className="mr-2"/> : <XCircle className="mr-2"/>}

                                <div className="pl-1">
                                    {program.status === 'Active' ? program.status : 'Disabled'}
                                </div>
                            </span>
                    </TableCell>)}
                    <TableCell>
                        <DotsMenu options={userOptions} />
                    </TableCell>

                </TableRow>
            )}
            )}
            </tbody>
        </Table>
    );
};

export default ProgramTable;
