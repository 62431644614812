import React from "react";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {persistor, store} from "store/store";
import App from "App";
import 'preline';
import reportWebVitals from "reportWebVitals";
import "assets/styles/index.css";
import {PersistGate} from "redux-persist/integration/react";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
);

reportWebVitals();
