import {createSlice} from '@reduxjs/toolkit';
import {showToast} from "components/ToastContainer";
import {CredentialThunks} from "./credentialThunks";

export interface CredentialState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    credentials?: any;
    refresh: number;
    pagy?: any;
    credential?: any;
    isEditing?: boolean;
}

const initialState: CredentialState = {
    status: 'idle',
    error: null,
    credentials: [],
    refresh: 0,
    credential: {name: '', nameAlias: ''},
    pagy: {},
    isEditing: false
};

const CredentialSlice = createSlice({
    name: 'credential',
    initialState,
    reducers: {
        setCredential: (state, action) => {
            state.credential = action.payload;
        },
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Create
            .addCase(CredentialThunks.create.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(CredentialThunks.create.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.credential = action.payload;
                state.refresh += 1;
                state.credentials = [...state.credentials, action.payload];
                showToast('Credential created successfully', "success");
            })
            .addCase(CredentialThunks.create.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Update
            .addCase(CredentialThunks.update.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(CredentialThunks.update.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedCredential = action.payload;
                state.credential = updatedCredential;
                
                const index = state.credentials.findIndex((item: any) => item.id === updatedCredential.id);
                if (index !== -1) {
                    state.credentials[index] = updatedCredential;
                }
                showToast('Credential updated successfully', "success");
            })
            .addCase(CredentialThunks.update.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Show
            .addCase(CredentialThunks.show.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(CredentialThunks.show.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.credential = action.payload;
            })
            .addCase(CredentialThunks.show.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Index
            .addCase(CredentialThunks.index.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(CredentialThunks.index.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.credentials = action.payload.data;
                state.pagy = action.payload.pagy;
            })
            .addCase(CredentialThunks.index.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Delete
            .addCase(CredentialThunks.delete.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(CredentialThunks.delete.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.refresh += 1;
                state.credentials = state.credentials.filter((item: any) => item.id !== action.payload);
                showToast('Credential deleted successfully', "success");
            })
            .addCase(CredentialThunks.delete.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export const {setCredential, setIsEditing} = CredentialSlice.actions;
export {initialState as credentialInitialState};
export default CredentialSlice.reducer;

export class setCredentials {
}
