    import apiClient from "services/apiClient";
    import {Program} from "types/program";
    import {SearchType} from "types/search";
    import {buildUrlWithParams} from "utils/apiUtils";
    
    export class ProgramService {
        static async index(page: number, search: SearchType<{ [key: string]: any}>, sortField: string, sortDirection: string, rowsPerPage: number) {
            const url = buildUrlWithParams({
                baseUrl: 'api/v1/programs',
                page: page,
                search: search,
                sortField: sortField,
                sortDirection: sortDirection,
                rowsPerPage: rowsPerPage,
            });

            const response = await apiClient.get(url);
            return response.data;
        }
        static async reactivate(id: string) {
            const response = await apiClient.patch(`api/v1/programs/${id}/reactivate`);
            return response.data;
        }
        static async deactivate(id: string) {
            const response = await apiClient.patch(`api/v1/programs/${id}/deactivate`);
            return response.data;
        }
        static async create(record: Program) {
            const response = await apiClient.post("api/v1/programs", {program: record.toJson()});
            return response.data;
        }
    
        static async show(id: string) {
            const response = await apiClient.get(`api/v1/programs/${id}`);
            return response.data;
        }
    
        static async update(record: Program) {
            const response = await apiClient.patch(`api/v1/programs/${record.id}`, {program: record.toJson()});
            return response.data;
        }
    
        static async delete(id: string) {
            const response = await apiClient.delete(`api/v1/programs/${id}`);
            return response.data;
        }
    }
