import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {Payer} from "types/payer";
import SubmitButton from "components/SubmitButton";
import {PayerThunks} from "features/payer/payerThunks";
import {setIsEditing} from "features/payer/payerSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import {Autocomplete, useLoadScript} from "@react-google-maps/api";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";
import CustomSelect from "components/inputs/CustomSelect";
import {Location} from "types/location";


interface PayerFormProps {
    id?: string;
    closeDrawer: () => void;
    phoneNumber?: string;

}

const PayerForm: React.FC<PayerFormProps> = ({id, closeDrawer}) => {
    const payer = useSelector((state: RootState) => state.payer.payer);
    const dispatch = useDispatch<AppDispatch>();
    const location = useSelector((state: RootState) => state.location.locations);
    const [updatedPayer, setUpdatedPayer] = useState({...payer});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
        libraries: ["places"]
    });
    const [phoneNumber, setPhoneNumber] = useState('');
    const locationOptions = location.map((location: Location) => ({
        value: location.id,
        label: location.name
    }));
    const payerTypeOptions = [
        { value: '1', label: 'Insurance' },
        { value: '2', label: 'Non Insurance' },
        { value: '3', label: 'Self Pay' },
    ];

    useEffect(() => {
        const isFormValid =
            updatedPayer.name &&
            updatedPayer.payerType &&
            updatedPayer.address &&
            updatedPayer.phoneNumber &&
            updatedPayer.locationIds?.length > 0;

        setIsSaveDisabled(!isFormValid);
    }, [
        updatedPayer.name,
        updatedPayer.payerType,
        updatedPayer.address,
        updatedPayer.phoneNumber,
        updatedPayer.description,
        updatedPayer.locationIds,
    ]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedPayer({...updatedPayer, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (payer?.id) {
            await dispatch(PayerThunks.update(new Payer(updatedPayer))).then(() => closeDrawer());
        } else {
            await dispatch(PayerThunks.create(new Payer(updatedPayer))).then(() => closeDrawer());
        }
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        closeDrawer();
    };
    const handleClickOutsideMaps = () => {
        const element = document.querySelector('.pac-container');
        if (element) {
            element.setAttribute('style', 'display: none');
        }
    };

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            const newPayer = { ...updatedPayer };

            if (place.formatted_address) {
                newPayer.address = place.formatted_address;
            }

            setUpdatedPayer(newPayer);
        }
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                    <div className="h-[158px] pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                        <div className=" mx-6 self-stretch flex-col justify-start items-start gap-1 flex">
                            <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                                {payer?.id ? 'Edit payer' : 'Create new payer'}
                            </div>

                            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                                {payer?.id ? 'Edit' : 'Create a new'} payer and set payer settings
                            </div>

                        </div>
                    </div>
                    <div className="flex-1 px-6 pb-6 ">
                        <div className="h-full ">
                            <div className="mt-5">
                                <form onSubmit={handleSubmit}>
                                    <div className="flex flex-col space-y-6 items-center mt-7">
                                        <div className="w-full">
                                            <TextInput
                                                id="name"
                                                type="text"
                                                label="Payer Name"
                                                value={updatedPayer?.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <DescriptionInput
                                                id="description"
                                                type="text"
                                                label="Description"
                                                value={updatedPayer?.description}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <div className="flex flex-col gap-[0.625rem]">
                                                <label htmlFor="payerTypeId"
                                                       className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                                    Type
                                                </label>
                                                <CustomSelect
                                                    options={payerTypeOptions}
                                                    onChange={(selectedOption) => {
                                                        setUpdatedPayer({
                                                            ...updatedPayer,
                                                            payerType: selectedOption ? selectedOption.value : '',
                                                        });
                                                    }}
                                                    value={updatedPayer?.payerType}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full ">
                                            <div className="w-full mb-6">
                                                {isLoaded && (
                                                    <Autocomplete
                                                        onLoad={(autocomplete) => setAutocomplete(autocomplete)}
                                                        onPlaceChanged={onPlaceChanged}
                                                        onUnmount={() => setAutocomplete(null)}
                                                    >
                                                        <TextInput
                                                            id="address"
                                                            type="text"
                                                            label="Address"
                                                            value={updatedPayer?.address}
                                                            onChange={handleChange}
                                                            onBlur={handleClickOutsideMaps}
                                                        />
                                                    </Autocomplete>
                                                )}
                                            </div>
                                            <div className="w-full mb-6">
                                                <PhoneNumberInput
                                                    id="phoneNumber"
                                                    label="Phone Number"
                                                    value={updatedPayer.phoneNumber || ""}
                                                    onChange={handleChange}
                                                    phoneNumber={phoneNumber}
                                                    setPhoneNumber={setPhoneNumber}
                                                />
                                            </div>
                                            <div>
                                                <div className="flex flex-col gap-[0.625rem] w-full">
                                                    <label htmlFor="roleId"
                                                           className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                                        Applicable Location(s)
                                                    </label>
                                                    <CustomSelect
                                                        options={locationOptions}
                                                        isMulti={true}
                                                        onChange={(selectedOptions) => {
                                                            const selectedValues = selectedOptions.map((option: any) => option.value);
                                                            setUpdatedPayer((prevData:Payer) => ({
                                                                ...prevData,
                                                                locationIds: selectedValues
                                                            }));
                                                        }}
                                                        value={updatedPayer.locationIds}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4  pt-5 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-fulljustify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit} label={(payer?.id ? 'Save' : 'Create new') + ' payer'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PayerForm;
