import { createSlice } from '@reduxjs/toolkit';
import { RootState } from "store/store";

export interface SidebarState {
    isOpen: boolean;
}

const initialState: SidebarState = {
    isOpen: true,
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.isOpen = !state.isOpen;
        }
    },
    extraReducers: (builder) => {


    },
});



export const { toggleSidebar } = sidebarSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.sidebar.isOpen;

export default sidebarSlice.reducer;
