    import React, {useEffect, useState} from "react";
    import { PayerThunks } from "features/payer/payerThunks";
    import {useDispatch, useSelector} from "react-redux";
    import {AppDispatch, RootState} from "store/store";
    import Drawer from "components/Drawer";
    import PayerTable from "features/payer/PayerTable";
    import PayerForm from "features/payer/PayerForm";
    import PayerDetails from "features/payer/PayerDetails";
    import {setPayer, setIsEditing} from "features/payer/payerSlice";

    const PayersPage: React.FC = () => {
        const [isDrawerOpen, setIsDrawerOpen] = useState(false);
        const openDrawer = () => setIsDrawerOpen(true);
        const closeDrawer = () => setIsDrawerOpen(false);
        const isEditing = useSelector((state: RootState) => state.payer.isEditing);

        return (
            <div>
                <div >
                    <div className="relative">
                        <PayerTable openDrawer={openDrawer}/>
                        <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                            {isEditing ? <PayerForm closeDrawer={closeDrawer} /> : <PayerDetails onClose={closeDrawer} />}
                        </Drawer>
                    </div>
                </div>
            </div>
        );
    };

    export default PayersPage;
