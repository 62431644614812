import apiClient from "services/apiClient";
import {UserInvitation} from "types/userInvitation";
import { toSnakeCaseString} from "utils/caseConverter";
import {SearchType} from "types/search";
import {toQueryString} from "utils/apiUtils";

export class UserInvitationService {
    static create = async (invitation: UserInvitation) => {
        const response = await apiClient.post("api/v1/user_invitations", {userInvitation: invitation.toJson()});
        return response.data;
    };

    static cancel = async (id: string) => {
        const response = await apiClient.patch(`api/v1/user_invitations/${id}/cancel`);
        return response.data;
    };

    static showByToken = async (token: string) => {
        const response = await apiClient.get(`api/v1/user_invitations/show_by_token?token=${token}`);
        return UserInvitation.fromJson(response.data);
    };

    static index = async (page: number, search: SearchType<{
        email: string
    }>, sortField: string, sortDirection: string, rowsPerPage: number) => {
        const response = await apiClient.get(`api/v1/user_invitations?page=${page}&sort[${toSnakeCaseString(sortField)}]=${sortDirection}&items=${rowsPerPage}&${toQueryString(search.where)}`);
        return response.data;
    };

    static show = async (id: string) => {
        const response = await apiClient.get(`api/v1/user_invitations/${id}`);
        return UserInvitation.fromJson(response.data);
    };
}





