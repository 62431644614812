export interface ProgramInterface {
    id: string;
    name: string;
    description: string;
    status: string;
    showUnapproved?: boolean;
    allowAlternateReports?: boolean;
    noteMinuteIncrement?: number;
    blockOverlappingNotes?: boolean;
    includeObjectivesOnNotes?: boolean;
    requireCurrentAuthorization?: boolean;
    enableEventReminders?: boolean;
}

export class Program implements ProgramInterface {
    id: string;
    name: string;
    description: string;
    status: string;
    showUnapproved?: boolean;
    allowAlternateReports?: boolean;
    noteMinuteIncrement?: number;
    blockOverlappingNotes?: boolean;
    includeObjectivesOnNotes?: boolean;
    requireCurrentAuthorization?: boolean;
    enableEventReminders?: boolean;

    constructor(data: Partial<ProgramInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.description = data.description!;
        this.status = data.status!;
        this.showUnapproved = data.showUnapproved;
        this.allowAlternateReports = data.allowAlternateReports;
        this.noteMinuteIncrement = data.noteMinuteIncrement;
        this.blockOverlappingNotes = data.blockOverlappingNotes;
        this.includeObjectivesOnNotes = data.includeObjectivesOnNotes;
        this.requireCurrentAuthorization = data.requireCurrentAuthorization;
        this.enableEventReminders = data.enableEventReminders;

    }

    toJson(): ProgramInterface {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            status: this.status,
            showUnapproved: this.showUnapproved,
            allowAlternateReports: this.allowAlternateReports,
            noteMinuteIncrement: this.noteMinuteIncrement,
            blockOverlappingNotes: this.blockOverlappingNotes,
            includeObjectivesOnNotes: this.includeObjectivesOnNotes,
            requireCurrentAuthorization: this.requireCurrentAuthorization,
            enableEventReminders: this.enableEventReminders
        };
    }
}
