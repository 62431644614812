import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import React, {useEffect, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {Credential} from "types/credential";
import SubmitButton from "components/SubmitButton";
import {CredentialThunks} from "features/credential/credentialThunks";
import {setIsEditing} from "features/credential/credentialSlice";
import DescriptionInput from "components/inputs/DescriptionInput";
import RadioInput from "components/inputs/RadioInput";
import GroupInput from "components/inputs/GroupInput";

interface CredentialFormProps {
    id?: string;
    closeDrawer: () => void;
}

const CredentialForm: React.FC<CredentialFormProps> = ({id, closeDrawer}) => {
    const credential = useSelector((state: RootState) => state.credential.credential);
    const dispatch = useDispatch<AppDispatch>();
    const [updatedCredential, setUpdatedCredential] = useState({...credential});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const isFormValid =
            updatedCredential.name &&
            updatedCredential.description &&
            updatedCredential.expires !== undefined &&
            (!updatedCredential.expires || (
                updatedCredential.remindHr !== undefined &&
                (!updatedCredential.remindHr || updatedCredential.hrReminderDays !== undefined) &&
                updatedCredential.remindUser !== undefined &&
                (!updatedCredential.remindUser || updatedCredential.userReminderDays !== undefined)
            )) &&
            updatedCredential.requiresTraining !== undefined &&
            (!updatedCredential.requiresTraining || updatedCredential.trainingLinks);

        setIsSaveDisabled(!isFormValid);
    }, [
        updatedCredential.name,
        updatedCredential.description,
        updatedCredential.expires,
        updatedCredential.remindHr,
        updatedCredential.hrReminderDays,
        updatedCredential.remindUser,
        updatedCredential.userReminderDays,
        updatedCredential.requiresTraining,
        updatedCredential.trainingLinks
    ]);

    useEffect(() => {
        setUpdatedCredential({...credential})
        setIsSaveDisabled(!credential?.nameAlias);
    }, [credential]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {id, value} = e.target;
        setUpdatedCredential({...updatedCredential, [id]: value});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (credential?.id) {
            await dispatch(CredentialThunks.update(new Credential(updatedCredential))).then(() => closeDrawer());
        } else {
            await dispatch(CredentialThunks.create(new Credential(updatedCredential))).then(() => closeDrawer());
        }
    };
    const handleRadioChange = (field: string, value: boolean) => {
        setUpdatedCredential((prevState: Credential) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleClose = () => {
        dispatch(setIsEditing(false));
        closeDrawer();
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div className="h-[158px] pt-6 pb-10 bg-sky-50 border-b border-sky-200 flex-col justify-start items-end gap-2 inline-flex w-full">
                    <div className=" mx-6 self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                            {credential?.id ? 'Edit credential' : 'Create new credential'}
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {credential?.id ? 'Edit' : 'Create '} credentials, associates licenses and reminders
                        </div>
                    </div>
                </div>
                <div className="h-full px-6 pb-6 ">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full">
                                    <TextInput
                                        id="name"
                                        type="text"
                                        label="Name"
                                        value={updatedCredential?.name}
                                        placeholder="Name"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <DescriptionInput
                                        id="description"
                                        type="text"
                                        label="Description"
                                        value={updatedCredential?.description}
                                        placeholder="Description"
                                        onChange={handleChange}
                                    />
                                    <div className="flex flex-col space-y-6 items-center mt-7">
                                        <RadioInput
                                            label="Expires"
                                            options={[
                                                {id: 'expiresYes', label: 'Yes', value: true},
                                                {id: 'expiresNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={updatedCredential.expires}
                                            onChange={(value) => handleRadioChange('expires', value)}
                                        />

                                        {updatedCredential.expires && (
                                            <>
                                                <div className="flex flex-col w-full space-y-2">
                                                    <div className="flex items-start justify-between">
                                                        <div className="flex-1 w-60">
                                                            <RadioInput
                                                                label="Remind HR before expiration?"
                                                                options={[
                                                                    {
                                                                        id: "remindHrYes",
                                                                        label: "Yes",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        id: "remindHrNo",
                                                                        label: "No",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                selectedValue={updatedCredential.remindHr}
                                                                onChange={(value) =>
                                                                    handleRadioChange("remindHr", value)
                                                                }
                                                            />
                                                        </div>
                                                        {updatedCredential.remindHr && (
                                                            <div className="flex justify-end ml-4 w-40">
                                                                <GroupInput
                                                                    id="hrReminderDays"
                                                                    type="number"
                                                                    label="Remind"
                                                                    value={(
                                                                        updatedCredential.hrReminderDays ?? ""
                                                                    ).toString()}
                                                                    onChange={handleChange}
                                                                    addon="days before"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="flex flex-col w-full space-y-2">
                                                    <div className="flex items-start justify-between">
                                                        <div className="flex-1 w-60">
                                                            <RadioInput
                                                                label="Remind staff before expiration?"
                                                                options={[
                                                                    {
                                                                        id: "remindUserYes",
                                                                        label: "Yes",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        id: "remindUserNo",
                                                                        label: "No",
                                                                        value: false,
                                                                    },
                                                                ]}
                                                                selectedValue={
                                                                    updatedCredential.remindUser
                                                                }
                                                                onChange={(value) =>
                                                                    handleRadioChange("remindUser", value)
                                                                }
                                                            />
                                                        </div>
                                                        {updatedCredential.remindUser && (
                                                            <div className="flex justify-end ml-4 w-40">
                                                                <GroupInput
                                                                    id="userReminderDays"
                                                                    type="number"
                                                                    label="Remind"
                                                                    value={( updatedCredential.userReminderDays ?? "").toString()}
                                                                    onChange={handleChange}
                                                                    addon="days before"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <RadioInput
                                            label="Requires training?"
                                            options={[
                                                {id: 'requiresTrainingYes', label: 'Yes', value: true},
                                                {id: 'requiresTrainingNo', label: 'No', value: false}
                                            ]}
                                            selectedValue={updatedCredential.requiresTraining}
                                            onChange={(value) => handleRadioChange('requiresTraining', value)}
                                        />
                                        {updatedCredential.requiresTraining && (
                                            <div className="flex flex-col w-full space-y-2">
                                                <div className="flex items-start justify-between">
                                                    <div className="flex-1 w-60">
                                                    <TextInput
                                                        id="trainingLinks"
                                                        type="text"
                                                        label="Training Links"
                                                        value={updatedCredential.trainingLinks}
                                                        onChange={handleChange}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton onClick={handleSubmit}
                                      label={(credential?.id ? 'Save' : 'Create new') + ' credential'}
                                      disabled={isSaveDisabled}
                        />
                    </div>
                    <div
                        className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button type="submit" onClick={handleClose}
                                className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>

    );
};

export default CredentialForm;
